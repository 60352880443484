.alerter {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: var(--padding-xsmall);
  width: 100%;

  a,
  i {
    height: 16px;
    width: 16px;
  }

  &.alerter-fixed {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
    left: var(--padding-small);
    max-width: 100%;
    position: fixed;
    right: var(--padding-small);
    top: var(--padding-small);
    width: auto;
    z-index: 9999;
  }

  &.success {
    color: var(--alert-success-color);
  }

  &.warning {
    color: var(--alert-warning-color);
  }

  &.error {
    color: var(--alert-error-color);
  }

  @media (min-width: $screen-small) {
    &.alerter-fixed {
      left: auto;
      max-width: 350px;
      width: auto;
    }
  }

  @media (min-width: $screen-medium) {
    padding: var(--padding-small);

    &.alerter-fixed {
      right: var(--padding-medium);
      top: var(--padding-medium);
    }
  }
}
