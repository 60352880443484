/*INPUT FIELDS*/

label {
  display: block;
}

.input {
  background-color: #fff;
  box-sizing: border-box;
  border: none;
  color: var(--color-grey-dark);
  height: var(--element-height-medium);
  outline: 0;
  padding: 0 var(--padding-xsmall);
  width: 100%;

  &[disabled] {
    color: var(--color-grey-medium);
    cursor: not-allowed;
  }

  &.error {
    border-bottom: solid 2px var(--color-red);
  }
}

textarea.input {
  padding: var(--padding-xsmall);
  min-height: 200px;
}

.input-group {
  display: grid;
  grid-row-gap: var(--padding-xsmall);
  grid-template-columns: 1fr min-content;
  grid-template-rows: auto;

  label {
    border-bottom: 1px solid #fff;
    grid-column: 1 / span 2;
    padding-bottom: 5px;
  }
}

@media (min-width: $screen-small) {
  .input {
    padding: 0 var(--padding-small);
  }

  textarea.input {
    padding: var(--padding-small);
  }
}
