﻿.icon-container {
  display: flex;
  align-items: center;
}

.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 30px;
  width: 30px;

  &.icon-left {
    margin-right: 5px;
  }

  &.icon-right {
    margin-left: 5px;
  }
  /*ARROWS*/
  &.icon-double-arrow {
    @include svg-transform($clr-white, $icon-double-arrow);
    height: 16px;
    width: 16px;
  }

  &.icon-arrow-right {
    @include svg-transform($clr-black, $icon-arrow-right);
    height: 16px;
    width: 16px;

    &.grey {
      @include svg-transform($clr-grey-light, $icon-arrow-right);
    }
  }

  &.icon-arrow-left {
    @include svg-transform($clr-black, $icon-arrow-left);
    height: 16px;
    transform: rotate(-180deg);
    width: 16px;
  }

  &.icon-arrow-down {
    @include svg-transform($clr-black, $icon-arrow-left);
    height: 16px;
    width: 16px;
    transform: rotate(90deg);
  }

  &.icon-arrow-up {
    @include svg-transform($clr-black, $icon-arrow-left);
    height: 16px;
    width: 16px;
    transform: rotate(-90deg);
  }

  &.icon-arrow-down-simple {
    @include svg-transform($clr-black, $icon-arrow-down);
    height: 16px;
    width: 16px;
  }

  &.icon-home {
    @include svg-transform($clr-grey-medium, $icon-home);
    background-size: 100% 100%;
    height: 12px;
    width: 13px;
  }
  /*BAG*/
  &.icon-bag {
    @include svg-transform($clr-black, $icon-bag);
    background-size: 20px 20px;
  }

  &.icon-bag-add {
    @include svg-transform($clr-green-light, $icon-bag);
    background-size: 20px 20px;

    &-grey-dark {
      @include svg-transform('#333333', $icon-bag);
      background-size: 20px 20px;
    }
  }

  &.icon-basket {
    @include svg-transform($clr-white, $icon-basket);
    background-size: 20px 20px;
  }

  &.icon-white-bag-add {
    @include svg-transform($clr-white, $icon-bag-white);
    background-size: 20px 20px;
  }

  &.icon-cart {
    @include svg-transform($clr-black, $icon-bag);
    background-size: 20px 20px;
  }

  &.icon-filter {
    @include svg-transform($clr-black, $icon-filter);
    background-size: 20px 20px;
  }

  &.icon-xmark {
    @include svg-transform($clr-black, $icon-xmark);
    background-size: 20px 20px;

    &.icon-gray {
      @include svg-transform('#989796', $icon-xmark);
    }
  }
  /*STOCK*/
  &.icon-in-stock {
    @include svg-transform($clr-black, $icon-in-stock);

    &.icon-green {
      @include svg-transform('#41A457', $icon-in-stock);
    }
  }

  &.icon-not-in-stock {
    @include svg-transform($clr-black, $icon-not-in-stock);

    &.icon-red {
      @include svg-transform('#EB5A65', $icon-not-in-stock);
    }

    &.icon-grey {
      @include svg-transform('#a6a6a6', $icon-not-in-stock);
    }

    &.icon-white {
      @include svg-transform($clr-white, $icon-not-in-stock);
    }
  }
  /*ALARM*/
  &.icon-alarm {
    @include svg-transform($clr-black, $icon-alarm);

    &.icon-red {
      @include svg-transform('#EB5A65', $icon-alarm);
    }

    &.icon-grey {
      @include svg-transform('#a6a6a6', $icon-alarm);
    }

    &.icon-white {
      @include svg-transform($clr-white, $icon-alarm);
    }
  }
  /*USER*/
  &.icon-headphone {
    @include svg-transform($clr-black, $icon-headphone);
  }

  &.icon-menu {
    @include svg-transform-stroke($clr-black, $icon-menu);
    background-size: 30px;
    height: 70px;
    width: 70px;
  }

  &.icon-search {
    @include svg-transform($clr-black, $icon-search);

    &-white {
      @include svg-transform($clr-white, $icon-search);
    }
  }

  &.icon-user {
    @include svg-transform($clr-black, $icon-user);
    background-size: 20px 20px;
  }
  /*/*WARNINGS*/
  &.icon-contains-nicotine {
    @include svg-transform($clr-black, $icon-contains-nicotine);
  }

  &.icon-not-under18 {
    @include svg-transform($clr-black, $icon-not-under18);
  }

  &.icon-under18 {
    @include svg-transform($clr-black, $icon-under18);
    height: 36px;
    width: 36px;
  }

  &.icon-close {
    @include svg-transform-stroke($clr-black, $icon-close);

    &.icon-grey {
      @include svg-transform-stroke('#414141', $icon-close);
    }
  }

  &.icon-check {
    @include svg-transform($clr-black, $icon-check);

    &.icon-white {
      @include svg-transform($clr-white, $icon-check);
    }
  }

  &.icon-delete {
    @include svg-transform($clr-black, $icon-delete);
  }

  &.icon-trash {
    @include svg-transform(#809fb8, $icon-trash);
  }
  // &.icon-star {
  //   @include svg-transform($clr-black, $icon-star);
  //   height: 20px;
  //   width: 20px;
  // }

  &.icon-error {
    @include svg-transform($clr-black, $icon-error);

    &.icon-white {
      @include svg-transform('#fff', $icon-error);
    }

    &.icon-red {
      @include svg-transform($clr-red, $icon-error);
    }
  }
  /*product card - usp*/

  &.icon-usp-delivery {
    background-image: url('~@assets/images/icons/icon-delivery.svg');
  }

  &.icon-usp-prices {
    background-image: url('~@assets/images/icons/icon-low-prices.svg');
  }

  &.icon-usp-snusdosa {
    background-image: url('~@assets/images/icons/icon-snusdosa.svg');
  }

  &.icon-flag {
    background-size: 30px;
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }

  &.icon-flag-uk {
    background-image: url('~@assets/images/icons/flags/united-kingdom.svg');
  }

  &.icon-flag-se {
    background-image: url('~@assets/images/icons/flags/sweden.svg');
  }

  &.icon-flag-eu {
    background-image: url('~@assets/images/icons/flags/european-union.svg');
  }

  &.icon-flag-de {
    background-image: url('~@assets/images/icons/flags/de-at.svg');
    background-size: 32px;
  }

  &.icon-flag-fi {
    background-image: url('~@assets/images/icons/flags/finland.svg');
  }

  &.icon-flag-al {
    background-image: url('~@assets/images/icons/flags/al.svg');
  }

  &.icon-flag-at {
    background-image: url('~@assets/images/icons/flags/at.svg');
  }

  &.icon-flag-au {
    background-image: url('~@assets/images/icons/flags/au.svg');
  }

  &.icon-flag-be {
    background-image: url('~@assets/images/icons/flags/be.svg');
  }

  &.icon-flag-bg {
    background-image: url('~@assets/images/icons/flags/bg.svg');
  }

  &.icon-flag-ch {
    background-image: url('~@assets/images/icons/flags/ch.svg');
  }

  &.icon-flag-cy {
    background-image: url('~@assets/images/icons/flags/cy.svg');
  }

  &.icon-flag-cz {
    background-image: url('~@assets/images/icons/flags/cz.svg');
  }

  &.icon-flag-dk {
    background-image: url('~@assets/images/icons/flags/dk.svg');
  }

  &.icon-flag-ee {
    background-image: url('~@assets/images/icons/flags/ee.svg');
  }

  &.icon-flag-el {
    background-image: url('~@assets/images/icons/flags/el.svg');
  }

  &.icon-flag-es {
    background-image: url('~@assets/images/icons/flags/es.svg');
  }

  &.icon-flag-fr {
    background-image: url('~@assets/images/icons/flags/fr.svg');
  }

  &.icon-flag-hr {
    background-image: url('~@assets/images/icons/flags/hr.svg');
  }

  &.icon-flag-hu {
    background-image: url('~@assets/images/icons/flags/hu.svg');
  }

  &.icon-flag-ie {
    background-image: url('~@assets/images/icons/flags/ie.svg');
  }

  &.icon-flag-is {
    background-image: url('~@assets/images/icons/flags/is.svg');
  }

  &.icon-flag-it {
    background-image: url('~@assets/images/icons/flags/it.svg');
  }

  &.icon-flag-li {
    background-image: url('~@assets/images/icons/flags/li.svg');
  }

  &.icon-flag-lt {
    background-image: url('~@assets/images/icons/flags/it.svg');
  }

  &.icon-flag-lu {
    background-image: url('~@assets/images/icons/flags/lu.svg');
  }

  &.icon-flag-lv {
    background-image: url('~@assets/images/icons/flags/lv.svg');
  }

  &.icon-flag-me {
    background-image: url('~@assets/images/icons/flags/me.svg');
  }

  &.icon-flag-mk {
    background-image: url('~@assets/images/icons/flags/mk.svg');
  }

  &.icon-flag-mt {
    background-image: url('~@assets/images/icons/flags/mt.svg');
  }

  &.icon-flag-nl {
    background-image: url('~@assets/images/icons/flags/nl.svg');
  }

  &.icon-flag-no {
    background-image: url('~@assets/images/icons/flags/no.svg');
  }

  &.icon-flag-pl {
    background-image: url('~@assets/images/icons/flags/pl.svg');
  }

  &.icon-flag-pt {
    background-image: url('~@assets/images/icons/flags/pt.svg');
  }

  &.icon-flag-ro {
    background-image: url('~@assets/images/icons/flags/ro.svg');
  }

  &.icon-flag-rs {
    background-image: url('~@assets/images/icons/flags/rs.svg');
  }

  &.icon-flag-si {
    background-image: url('~@assets/images/icons/flags/si.svg');
  }

  &.icon-flag-sk {
    background-image: url('~@assets/images/icons/flags/sk.svg');
  }

  &.icon-flag-tr {
    background-image: url('~@assets/images/icons/flags/tr.svg');
  }

  &.icon-flag-us {
    background-image: url('~@assets/images/icons/flags/icon-flag-us.svg');
  }
}

  .icon-circle {
    border: 4px solid #fff;
    border-radius: 50%;
    display: inline-block;
    height: 22px;
    width: 22px;

    &.icon-circle-full {
      background-color: #fff;

      &.icon-circle-yellow {
        background-color: var(--color-yellow);
      }
    }

    &.icon-circle-yellow {
      border: 4px solid var(--color-yellow);
    }
  }

  .icon-bar {
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    height: 8px;
    padding: 2px;
    width: 44px;

    i {
      border-radius: 4px;
      display: flex;

      &.icon-bar-red {
        background-color: var(--color-pink);
      }

      &.icon-bar-blue {
        background-color: var(--color-blue);
      }
    }
  }

  .flag {
    align-items: center;
    background-color: var(--color-red);
    border-radius: 50%;
    color: #fff;
    display: flex;
    height: 70px;
    justify-content: center;
    width: 70px;
    background-color: #ffffff !important;
    color: #000 !important;
    border: 1px solid #b5b5b5 !important;

    span {
      align-items: center;
      border: 2px solid var(--color-blue);
      border-radius: 50%;
      display: flex;
      height: 66px;
      justify-content: center;
      text-align: center;
      width: 66px;
    }

    &.alttext {
      background-color: var(--color-blue-dark);
      font-size: 12px;

      span {
        border-color: var(--color-blue);
      }
    }

    &.campaign {
      background-color: var(--color-red);

      span {
        border-color: var(--color-pink);
      }
    }

    &.blackfriday {
      background-color: var(--color-black);

      span {
        border-color: var(--color-black);
        color: var(--color-white);
      }
    }

    &.nofreight,
    &.new {
      background-color: var(--color-green-light);

      span {
        border-color: var(--color-green);
      }
    }
  }
