﻿header {
  background-color: var(--header-background);
  width: 100%;
  height: auto;
  display: grid;
  grid-template-areas: var(--header-grid-area-mobile);
  grid-template-columns: var(--header-grid-columns-mobile);
  grid-template-rows: var(--header-grid-rows-mobile);
  position: sticky;
  top: 0;
  z-index: 99;
  padding: var(--padding-xsmall);
  align-items: center;
  justify-content: space-between;
  margin: auto;
  transition: height 1s ease;
  margin: auto 0;

  a {
    text-decoration: none;
  }

  .hidden {
    display: none;
  }

  #search-bar {
    grid-area: search;

    &.responsive-search {
      @media (max-width: $screen-small) {
        display: none;
      }
    }
  }

  #product-page-search.responsive-search {
    @media (min-width: $screen-small) {
      display: none;
    }
  }

  .header-logos {
    grid-area: logo;
    display: grid;
    grid-template-columns: auto auto;
    place-items: center;

    .site-logo {
      background-image: var(--logo);
      background-position: left;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      margin: 0;
      height: var(--logo-height-mobile);
      width: var(--logo-width-mobile);
      margin-right: var(--padding-xsmall);
      text-indent: -9999px;
    }

    .eighteen {
      width: 30px;
      height: 30px;

      .cls-1 {
        fill: #fcd600;
      }

      .cls-2 {
        fill: #b20029;
      }
    }
  }

  .header-actions {
    grid-area: actions;
    @include flex();
    @include flex-end();

    a {
      .icon {
        height: 50px;
        width: 50px;
      }
    }

    .location-selector:hover {
      > a.active {
        &::after {
          transform: all 0.2s ease;
          transform: rotate(90deg);
        }
      }
    }

    .location-selector,
    .header-previewbasket {
      display: none;
    }

    .header-user,
    .header-menu {
      .icon {
        background-size: 20px 20px;
      }

      > span {
        display: none;
      }
    }

    .header-user, .header-previewbasket {
      width: 32px;

      @media (min-width: $screen-small) {
        width: auto;
      }
    }

    .header-previewbasket {
      /*      position: fixed;
      bottom: 0;
      left: 0;*/
      /*      background-color: var(--color-white);
      box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.1);*/
      /*width: 100%;*/
      display: block;
      /*      display: grid;
      grid-template-columns: repeat(2, 50%);*/
      height: 50px;
      place-items: center center;
      z-index: 6000;

      > a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        > span {
          display: none;

          @media (min-width: $screen-small) {
            display: inline-block;
          }
        }

        .icon {
          background-size: 20px 20px;
        }

        .previewBasket-totalQty {
          position: absolute;
          top: 0;
          right: -10%;

          @media (min-width: $screen-small) {
            position: relative;
            right: 0;
          }
        }

        &.basket-gotocheckout {
          display: none;

          > * {
            pointer-events: none;
          }

          > i {
            margin-left: var(--padding-xsmall);
          }
        }
      }

      .basket-icon {
        text-decoration: none;

        .previewBasket-totalQty {
          margin-left: var(--padding-mini);
          background-color: var(--color-green);
          border-radius: 50%;
          color: #fff;
          text-align: center;
          font-size: 9px;
          display: inline-flex;
          width: 20px;
          height: 20px;
          justify-content: center;
          align-items: center;
        }
      }
    }
    // div[data-container='minibasket'] {
    //   display: none;
    // }
  }

  .main-search {
    grid-area: search;
    padding: 0;
  }

  @media (min-width: $screen-medium) {
    grid-template-areas: var(--header-grid-area);
    grid-template-columns: var(--header-grid-columns);
    grid-template-rows: var(--header-grid-rows);
    padding: var(--padding-small);

    .header-logos {
      .site-logo {
        height: var(--logo-height);
        width: var(--logo-width);
      }

      .eighteen {
        width: 50px;
        height: 50px;
      }
    }

    .header-actions {
      @include flex-center();

      a {
        .icon {
          width: 30px;
        }

        width: auto;
      }

      .location-selector.custom-select {
        display: block;

        > a.active {
          padding: 0 calc(var(--padding-small) * 2) 0 var(--padding-small);

          &::after {
            right: 8px;
          }
        }

        > a.active,
        a.option {
          > i.icon.icon-flag {
            height: 20px;
            width: 20px;
          }
        }

        .custom-select-options {
          min-width: 220px;
        }
      }

      .header-user,
      .header-menu {
        > span {
          display: block;
        }
      }

      .header-previewbasket {
        position: relative;
        bottom: unset;
        left: unset;
        background-color: unset;
        box-shadow: unset;
        width: auto;
        display: grid;
        grid-template-columns: unset;
        place-items: unset;
        grid-template-columns: auto;
        z-index: unset;
        min-width: 80px;
        margin-left: 10px;

        .basket-icon {
          border: none;
        }

        .basket-gotocheckout {
          display: none;
        }
      }
    }

    &.shrink {
      height: var(--header-shrink-height);
    }
  }
}
