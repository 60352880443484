/* Default Colors */
$clr-brown: #8d683b;
$clr-grey-menutoggle: #e4e4e4;
$clr-grey-light: #d1cfcf;
$clr-grey-light-second: #efeeed;
$clr-grey-lighter: #efefef;
$clr-grey-lightest: #EDEDED7A;
$clr-grey-medium: #a6a6a6;
$clr-grey-dark: #4d4d4d;
$clr-grey-mid: #999999;
$clr-blue-grey: #d1dce3;
$clr-blue-dark: #1d3263;

$clr-blue: #809fb8;
$clr-blue-light: #91b4d1;

$clr-blue-lighter: #b9ccdb;

$clr-green-lighter: #54b968;
$clr-green-light: #43a465;
$clr-green: #3c935a;

$clr-green-dark: #3d9751;
$clr-green-darker: #4d8661;
$clr-green-super-light: #cbe5dc;
$clr-pink: #f59790;
$clr-red-light: #f8e9e5;
$clr-red: #d5394b;
$clr-white: #fff;
$clr-white-darker: #f1f1f1;
$clr-black: #000;
$clr-yellow-light: #fbf0c4;
$clr-yellow: #fc0;
$clr-red-dark: #e63946;

/* Haypp colors */
$haypp-clr-grey-light: #f0f1f3;
$haypp-clr-lighter: #f8f8f8;
$haypp-clr-dark: #172029;
$haypp-clr-green-light: #47b05e;
$haypp-clr-green: #41a457;
$haypp-clr-green-bright: #00B451;
$haypp-clr-red: #eb5a65;
$haypp-clr-font: #414141;

/* Container */
$container-width-small: 1100px;
$container-width: 1400px;
$container-width-large: 1600px;

/* Padding */
$padding-mini: 5px;
$padding-xsmall: 10px;
$padding-small: 20px;
$padding-medium: 40px;
$padding-large: 60px;

/* Gap-column */
$gap-column-mini: $padding-mini;
$gap-column-xsmall: $padding-xsmall;
$gap-column-small: $padding-small;
$gap-row-small: $padding-small;
$gap-column-medium: $padding-medium;
$gap-row-medium: $padding-medium;

/* Screen size */
$screen-xsmall: 480px;
$screen-small: 768px;
$screen-medium: 1024px;
$screen-large: calc(var(--container-width) + (var(--gap-column-medium) * 2));
$screen-xlarge: calc(var(--container-width-large) + (var(--gap-column-medium) * 2));

/* Icons */
$icon-arrow-right: 'images/icons/icon-arrow-right.svg';
$icon-arrow-left: 'images/icons/icon-arrow-left.svg';
$icon-arrow-down: 'images/icons/icon-arrow-down.svg';
$icon-double-arrow: 'images/icons/icon-double-arrow.svg';
$icon-bag: 'images/icons/icon-bag.svg';
$icon-home: 'images/icons/icon-home.svg';
$icon-bag-white: 'images/icons/icon-bag-white.svg';
$icon-basket: 'images/icons/icon-basket.svg';
$icon-in-stock: 'images/icons/icon-in-stock.svg';
$icon-not-in-stock: 'images/icons/icon-not-in-stock.svg';
$icon-headphone: 'images/icons/icon-headphone.svg';
$icon-under18-white: 'images/icons/icon-under18-white.svg';
$icon-close: 'images/icons/icon-close-alt.svg';
$icon-menu: 'images/icons/icon-menu.svg';
$icon-menu-white: 'images/icons/icon-menu-white.svg';
$icon-search: 'images/icons/icon-search.svg';
$icon-search-white: 'images/icons/icon-search-white.svg';
$icon-user: 'images/icons/icon-user.svg';
$icon-user-white: 'images/icons/icon-user-white.svg';
$icon-contains-nicotine: 'images/icons/icon-contains-nicotine.svg';
$icon-not-under18: 'images/icons/icon-not-under18.svg';
$icon-under18: 'images/icons/icon-under18.svg';
$icon-check: 'images/icons/icon-check.svg';
$icon-error: 'images/icons/icon-error.svg';
$icon-delete: 'images/icons/icon-delete-alt.svg';
$icon-trash: 'images/icons/icon-trash.svg';
$icon-flag-us: 'images/icons/flags/icon-flag-us.svg';
$icon-star: 'images/icons/icon-star.svg';
$icon-star-fill: 'images/icons/icon-star-fill.svg';
$icon-star-outline: 'images/icons/icon-star-outline.svg';
$icon-filter: 'images/icons/filter.svg';
$icon-xmark: 'images/icons/icon-x.svg';
$icon-alarm: 'images/icons/icon-alarm.svg';
$icon-usp-customerservice: 'images/northerner/footer_1.svg';
$icon-usp-shipping: 'images/northerner/footer_2.svg';
$icon-usp-discounts: 'images/northerner/footer_3.svg';
$icon-usp-freshproducts: 'images/northerner/footer_4.svg';
