@import '@components/basketproducts.scss';
@import '@components/checkoutSummary.scss';

body[data-basket='1'] {
  .preview-basket {

    &.open {
      > .preview-basket-container {
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100);
        @media (min-width: $screen-medium) {
          height: auto;
        }
      }
    }
  }
}

.preview-basket {
  display: none;
  visibility: hidden;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 6000;
  bottom: 0;
  left: 0;
  opacity: 0;

  .box-warning {
    p {
      padding: 0;
      margin: 0;
      color: var(--color-black);
      font-weight: 600;
      text-align: center;
    }

    border: solid 2px var(--color-black);
    padding: var(--padding-small);
    margin: var(--padding-mini);

    @media (max-width: $screen-xsmall) {
      border: solid 2px var(--color-black);
      padding: var(--padding-mini);

      p {
        font-size: 12px;
      }
    }
  }

  &.open {
    display: block;
    visibility: visible;
    opacity: 1;

    > .preview-basket-container {
      position: relative;
      opacity: 1;
      pointer-events: auto;
      background-color: var(--color-white);
      // height: 20vh;
      height: 100vh;
      width: 80vw;
      overflow: hidden;
      bottom: 0;
      // left: 0;
      right: 0;
      position: absolute;

      @media (min-width: $screen-medium) {
        max-width: 500px;
      }
      // .cart-footer {
      //   display: none;
      // }
    }
  }

  > .preview-basket-container {
    background-color: var(--color-white);
    width: 100%;
    transition: height 0.2s ease-out;

    > .preview-basket-content {
      padding: 0;
      position: relative;

      @media (max-width: $screen-medium) {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      > .preview-basket-error {
        background-color: var(--color-red);
        padding: var(--padding-small);
        margin-bottom: var(--padding-small);
        display: flex;
        justify-content: space-between;
        align-items: center;

        > i {
          cursor: pointer;
        }

        > div {
          color: #fff;
          margin: 0;
          padding: 0;
          @include fluid-size(600, 1200, 18, 20);
        }
      }

      > .basket-products {
        overflow-y: auto;
        overflow-x: hidden;
        height: 60vh;

        @media (max-width: $screen-medium) {
          height: auto;
          flex-grow: 1;
        }

        > .basket-row {
          grid-template-columns: auto 1fr auto;
          background-color: var(--color-white);
          padding: var(--padding-xsmall);
          margin-bottom: var(--padding-xsmall);

          &:last-child {
            margin-bottom: var(--padding-small);
          }

          .price {
            .unit-price {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .icon-delete {
              background-size: 12px;
              height: 20px;
              width: 20px;
              border-radius: var(--element-radius);
              margin: 0;
            }
          }
        }
      }

      > .goto-checkout {
        display: none;
        margin: var(--padding-small) 0;

        > a {
          width: 80%;
          margin: auto;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        > .items-count {
          float: right;
        }
      }

      > .emptybasket {
        text-align: center;
        padding: var(--padding-small) var(--padding-medium) var(--padding-medium) var(--padding-medium);

        h4 {
          margin: 1rem 0;
        }

        a.continueShopping {
          cursor: pointer;
          font-size: 1.2rem;
          margin: 0 auto;
        }

        @media (max-width: $screen-medium) {
          margin: auto;
        }
      }

      > .header {
        background-color: var(--color-white-darker);
        position: relative;
        display: flex;
        justify-content: space-between;
        align-content: center;
        padding: var(--padding-small);

        > h4 {
          color: var(--color-green);
          margin: 0;
        }

        > i {
          cursor: pointer;
          height: auto;
          width: 22px;

          &:hover {
            color: rgb(172, 24, 127);
          }
        }
      }

      .preview-basket-bottom {
        position: fixed;
        // bottom: 50px;
        bottom: 0;
        right: 0;
        z-index: 10;
        width: 80vw;
        background-color: var(--color-white-darker);
        // padding: var(--padding-small);
        @media (max-width: $screen-medium) {
          position: relative;
        }

        .basket-summary {
          padding: var(--padding-small);
        }

        .checkout-redirect {
          margin: 0;

          > a {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          > .button {
            border-radius: 0 0 var(--element-radius) var(--element-radius);
          }
        }
      }
    }
  }

  @media (min-width: $screen-medium) {
    position: absolute;
    width: auto;
    height: auto;
    top: 90%;
    max-width: 500px;
    background-color: var(--color-white);
    border-radius: 0 0 0 20px;
    opacity: 0;
    pointer-events: none;
    height: 0;
    right: 0;
    left: unset;

    &.open {
      display: block;
      visibility: visible;
      opacity: 1;

      > .preview-basket-container {
        opacity: 1;
        pointer-events: auto;
        background-color: var(--color-white);
        height: auto;
        overflow: hidden;
        bottom: unset;
        left: unset;
        position: relative;
        /*max-height: calc(100vh - 100px);*/
        overflow: auto;
        border-radius: 0 0 var(--element-radius) var(--element-radius);
        // .cart-footer {
        //   display: none;
        // }
      }
    }

    > .preview-basket-container {
      width: auto;
      height: auto;
      bottom: unset;
      right: unset;
      position: relative;
      background-color: #fff;
      box-shadow: 2px 14px 14px rgba(0, 0, 0, 0.2);
      position: relative;

      > .preview-basket-content {
        margin-bottom: 0;
        padding: 0;
        border-radius: 0 0 0 var(--element-radius);

        > .basket-products {
          overflow-y: auto;
          overflow-x: hidden;
          /*max-height: calc(90vh - 100px);*/
          /*height: auto;*/
          padding-bottom: 200px;

          > .basket-row {
            grid-template-columns: auto 1fr auto;
            grid-gap: var(--padding-xsmall) var(--padding-xsmall);

            > .name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .preview-basket-bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 10;
          width: 100%;
          padding: 0;

          .basket-summary {
            padding: var(--padding-small);
          }

          .cart-footer {

            > .products-count {
              display: none;
            }
            // > .checkout-redirect {
            //   margin: 0;
            //   > a {
            //     width: 100%;
            //     align-items: center;
            //     justify-content: center;
            //   }


            > .button {
              border-radius: 0;
            }
          }
        }
      }
    }
  }
}


.previewBasketOpen {
  overflow: hidden;

  .basket-gotocheckout {
    background-color: var(--color-green);
    color: var(--color-white);
  }
}
