﻿.paging-container {
  background: var(--color-white);
  border-radius: 5px;
  text-align: center;
  color: var(--color-grey-medium);
  display: flex;
  flex-direction: row;

  .page-nav-button {
    width: 50px;
    height: 40px;
    margin: 0 var(--padding-xsmall);
    align-self: center;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.293031 1.29302C0.480558 1.10555 0.734866 1.00023 1.00003 1.00023C1.26519 1.00023 1.5195 1.10555 1.70703 1.29302L5.00003 4.58602L8.29303 1.29302C8.38528 1.19751 8.49562 1.12133 8.61763 1.06892C8.73963 1.01651 8.87085 0.988924 9.00363 0.98777C9.13641 0.986616 9.26809 1.01192 9.39098 1.0622C9.51388 1.11248 9.62553 1.18673 9.71943 1.28062C9.81332 1.37452 9.88757 1.48617 9.93785 1.60907C9.98813 1.73196 10.0134 1.86364 10.0123 1.99642C10.0111 2.1292 9.98354 2.26042 9.93113 2.38242C9.87872 2.50443 9.80254 2.61477 9.70703 2.70702L5.70703 6.70702C5.5195 6.89449 5.26519 6.99981 5.00003 6.99981C4.73487 6.99981 4.48056 6.89449 4.29303 6.70702L0.293031 2.70702C0.10556 2.51949 0.000244141 2.26518 0.000244141 2.00002C0.000244141 1.73486 0.10556 1.48055 0.293031 1.29302Z' fill='%23999999'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
    cursor: pointer;

    @media (min-width: $screen-medium) {
      height: 60px;
    }
  }

  span.page-nav-button {
    opacity: 0.3;
    cursor: default;
  }



  .prev {
    transform: rotate(90deg);
  }

  .next {
    transform: rotate(-90deg);
  }

  .page-nav-list {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: var(--padding-xsmall);
    border-left: 1px solid var(--color-grey-lighter);
    border-right: 1px solid var(--color-grey-lighter);

    @media (min-width: $screen-medium) {
      padding: var(--padding-small);
    }

    > div {
      text-align: center;
      display: inline-block;


      span {
        pointer-events: none;
      }

      a {
        color: var(--color-grey-medium);
        padding: 0 1rem;
        font-size: 14px;
        display: inline-block;
        transition: 0.2s color ease;

        @media (min-width: $screen-medium) {
          font-size: 16px;

          &:hover {
            color: var(--color-grey-dark);
            text-decoration: none;
          }
        }
      }

      > span {
        padding: 0 1rem;
        font-size: 14px;
        display: inline-block;

        @media (min-width: $screen-medium) {
          font-size: 16px;
        }
      }

      button {
        width: 100%;
        height: 100%;
      }
    }

    .activePage {

      button {
        cursor: unset;
      }


      > span {
        color: var(--color-black);
        position: relative;

        &::after {
          content: '';
          width: calc(100% - 2rem);
          height: 2px;
          background-color: var(--color-black);
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
