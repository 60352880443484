/*CHECKBOX*/

.checkbox {
  input[type="checkbox"] {
    display: none;
  }

  label {
    cursor: pointer;
    line-height: 24px;
    padding-left: 34px;
    position: relative;

    &::before {
      background-color: #fff;
      border-radius: 2px;
      content: "";
      display: block;
      height: 24px;
      left: 0;
      position: absolute;
      top: 0;
      width: 24px;
    }

    &::after {
      background-color: #fff;
      border-radius: 1px;
      content: "";
      display: block;
      height: 18px;
      left: 3px;
      position: absolute;
      top: 3px;
      width: 18px;
    }
  }

  > input[type="checkbox"]:checked ~ label::after {
     background-color: var(--color-pink);
  }

  > input[type="checkbox"]:focus ~ label::before {
    outline: var(--color-pink) auto 2px;
  }

  > input[type="checkbox"]:hover ~ label::before {
    outline: var(--color-pink) auto 2px;
  }

  &.checkbox-form {
    height: var(--element-height-small);
  }

  @media (min-width: $screen-medium) {
    &.checkbox-form {
      height: var(--element-height-medium);
    }
  }
}