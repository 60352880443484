﻿.filter-container {
  padding-bottom: var(--padding-small);

  &::before {
    background-color: rgba(0 0 0 / 0.5);
    content: '';
    width: 100vw;
    height: 100vh;
    z-index: 6100;
    position: fixed;
    left: 0;
    top: 0;
    right: -100vw;
    transition: all 0.3s ease-out;
    opacity: 0;
    pointer-events: none;
  }

  &[data-open] {
    &::before {
      left: 0;
      right: 0;
      transition: all 0.3s ease-out;
      opacity: 1;
      pointer-events: visible;
    }

    > .filter-content {
      right: 0;
    }
  }

  > .filter-content {
    transition: all 0.3s ease-out;
    position: fixed;
    top: 0;
    right: -80vw;
    width: 80vw;
    height: 100vh;
    z-index: 6200;
    background-color: var(--color-white);
  }

  .view-mobile-actions {
    @include visible();
    display: grid;
    pointer-events: visible;
    position: fixed;
    bottom: var(--padding-small);
    width: 100%;
    overflow: hidden;
    padding: 0 20px;

    .filter-close {
      @include flex();
      align-items: center;
      justify-content: center;
      width: calc(100% - 20vw);
      cursor: pointer;
    }
  }

  .filter-inner {
    background-color: var(--filter-background);
    border-radius: var(--element-radius);
    display: flex;
    flex-wrap: wrap;

    align-items: center;

    margin-bottom: 0;

    .filter-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      font-size: var(--filter-font-header-size);
      color: var(--font-color);
      padding: var(--padding-xsmall) var(--padding-small);

      i {
        float: right;
      }
    }

    .custom-select {
      border: none;
      background-color: var(--color-grey-lighter);
      height: var(--filter-input-height);
      font-size: var(--filter-font-size);
      border-radius: var(--element-radius);
      z-index: 50;
      width: 100%;
      display: block;
      position: relative;

      a {
        height: var(--filter-input-height);
        padding: 0 var(--padding-large) 0 var(--padding-small);
      }

      .custom-select-options {
        padding: var(--padding-xsmall);
        position: relative;
        //border-radius: 5px;
        border-radius: var(--element-radius);
        text-transform: capitalize;
        min-width: -webkit-fill-available;
        width: 100%;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        max-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 0;

        .option {
          padding: 0 0 var(--padding-xsmall) 0;

          .filter-range {
            width: max-content;
            padding-right: var(--padding-medium);
            padding-left: 30px;
            position: relative;
            right: 22px;
          }

          .filter-hits {
            margin-left: auto;
            width: 38px;
            text-align: center;
          }
        }
      }
    }

    .filter-content {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 2px;
      align-items: center;
    }

    .clear-all-filters {
      display: block;
      color: var(--color-white);
      background-color: var(--color-grey-dark);
      border-radius: 5px;
      height: var(--filter-input-height);
      font-weight: 100;
      font-size: var(--filter-font-size);
      padding: 5px 10px;
      box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 30%);

      @media (min-width: $screen-small) {
        margin-left: auto;
      }

      @media (max-width: $screen-medium) {
        display:none;
      }


      &:hover {
        background-color: var(--clear-all-filters-bg-clr-hover);
      }
    }
  }

  .sortorder {
    //padding: var(--padding-small) var(--padding-xsmall);
    font-size: var(--filter-font-size);
    //margin-top: var(--padding-small);

    > span {
      display: block;
      padding: var(--padding-xsmall) var(--padding-small);
      font-size: var(--filter-font-header-size);
    }

    @media (min-width: $screen-medium) {
      float: right;
    }

    select {
      width: 100%;
      height: var(--filter-input-height);
      background-color: var(--color-grey-lighter);
      border-radius: var(--element-radius);
    }
  }

  .active-filters-container {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    margin: var(--padding-small) 0;
    padding: 0 var(--padding-small);
    margin-top: 60px;
    

    .clear-filter {
      border: 1px solid black;
      border-radius: 50px;
      height: 28px;
      margin-right: 8px;
      margin-bottom: 8px;
      padding: 2px 5px;
      cursor: pointer;

      @media (min-width: $screen-small) {
        height: var(--filter-input-height);
        margin-right: 12px;
        margin-bottom: 12px;
        padding: 5px 10px;
      }

      .active-filters {
        padding: 5px;
        text-align: center;
        width: max-content;
        display: block;
        font-size: var(--filter-font-size);
        height: 100%;
        position: relative;
        bottom: 19px;
        text-transform: capitalize;

        icon {
          width: 12px;
          position: relative;
          top: 11px;
        }
      }
    }
  }

  // filterview-range
  @media (min-width: $screen-medium) {
    --filter-input-height: 35px;

    &::before {
      background-color: transparent;
      content: '';
      width: 100vw;
      height: 100vh;
      z-index: -1;
      position: unset;
      right: -100vw;
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }

    > .filter-content {
      position: relative;
      top: unset;
      right: unset;
      width: unset;
      height: unset;
      z-index: unset;
      background-color: unset;
    }

    .filter-inner {
      padding: var(--padding-small);
      gap: 10px;

      .filter-header {
        grid-column: 1 / span 3;
        color: var(--color-grey-mid);
        font-size: inherit;
        padding: 0 var(--padding-xsmall) 0 0;
      }

      .filter-content {
        gap: 20px;
      }

      .custom-select {
        width: max-content;
        .custom-select-options {
          // padding: var(--padding-xsmall) 0 var(--padding-xsmall) var(--padding-xsmall);
          width: max-content;
          position: absolute;

          .option {
            padding: 2px 0;
          }
        }
      }
    }

    .active-filters-container {
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
      margin: var(--padding-small) 0;
      padding: 0;
    }

    .sortorder {
      padding: 0;
      margin-top: var(--padding-xsmall);

      > span {
        display: inline;
        padding: 0;
        font-size: inherit;
      }

      select {
        background-color: var(--color-white);
        width: auto;
        margin-right: 0;
      }
    }

    .view-mobile-actions {
      @include hidden();
    }
  }
}

/* Filter mobile */
.filter-mobile {
  @include flex();
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  border-radius: var(--element-radius);
  background-color: var(--color-green-lighter);
  //background-color: var(--color-white);
  border-bottom: solid 1px #ccc;
  z-index: 2000;
  color: var(--font-color);
  padding: 0;
  height: 50px;
  width: 100vw;
  font-size: 14px;


  > div {
    padding: 0 20px;

    > i {
      margin-right: var(--padding-xsmall);
    }
  }

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .clear-filter-mobile {
    background-color: rgba(255, 255, 255, .4);

    > button {
      background: none;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 0;
    }
  }


  .hide-clear {
    display: none;
  }


  &:hover,
  &:active {
    background-color: darken($clr-green, 5%);
  }

  @media (min-width: $screen-medium) {
    visibility: hidden;
    display: none;
    pointer-events: none;
  }
}


