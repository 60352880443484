/*TABS*/

ul.tabs {
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--gap-row-small);
  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
  margin: 0;
  padding: 0;

  li {
    list-style: none;

    a.button {
      background-color: var(--color-grey-light);
      display: flex;
      justify-content: center;

      &.selected {
        background-color: #fff;
      }
    }
  }

  @media (min-width: $screen-medium) {
    grid-gap: var(--gap-row-medium);
  }
}

.tab-container {
  > .tab-content:not(.selected) {
    display: none;
  }

  > .tab-content {
     > h3 span, > p span, > ul li span {
          font-size: inherit !important;
          font-family: 'Roboto' !important;
      }

      ul[type="disc"] {
          list-style: disc;
          padding-left: 20px;
      }
  }
}
