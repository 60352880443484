﻿article.article-card {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .text-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--padding-xsmall);

    .header {
      flex-grow: 1;
    }

    .link {
      text-decoration: underline;
    }
  }

  &.article-large {
    background-color: transparent;
    grid-column: 1 / span 2;
    grid-row: 1;
    
    .text-inner {
      padding: 0;

      .header {
        flex-grow: 0;
      }
    }
  }

  @media (min-width: $screen-medium) {
    .text-inner {
      padding: var(--padding-small);
    }

    &.article-large {
      grid-column: 1 / span 4;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: min-content;

      .text-inner {
        grid-column: 1 / span 1;
        grid-row: 1;
      }
    }
  }
}