﻿.faq {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--padding-xsmall) 0;

  a {
    margin-top: var(--padding-xsmall);
    text-decoration: none;

    &:hover {
      color: var(--color-blue);
      text-decoration: none;
    }
  }
}

.media-text-section {
  @media (max-width: $screen-small) {
    grid-template-columns: 100% !important;
  }

  @media (min-width: $screen-medium) {
    &.media-right {
      img,
      form {
        grid-column: 2;
        grid-row: 1;
      }
    }
  }
}

.quote-container {
  padding: var(--padding-small);

  .quote {
    position: relative;

    .quote-left {
      position: absolute;
      top: -var(--padding-xsmall);
    }

    .quote-right {
      position: absolute;
      bottom: 0;
      right: 0;
      line-height: 0;
    }

    .text {
      padding: var(--padding-small);
      display: block;
    }
  }

  .author {
    padding: 0 var(--padding-small);
  }
}

.flexible-columns {
  &-2,
  &-3,
  &-4,
  &-5,
  &-6,
  &-7,
  &-8,
  &-9,
  &-10,
  &-11,
  &-12 {
    grid-template-columns: 1fr;
  }

  @media (min-width: $screen-small) {
    grid-template-columns: repeat(1, 1fr);

    &-2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &-4 {
      grid-template-columns: repeat(4, 1fr);
    }

    &-5 {
      grid-template-columns: repeat(5, 1fr);
    }

    &-6 {
      grid-template-columns: repeat(6, 1fr);
    }

    &-7 {
      grid-template-columns: repeat(7, 1fr);
    }

    &-8 {
      grid-template-columns: repeat(8, 1fr);
    }

    &-9 {
      grid-template-columns: repeat(9, 1fr);
    }

    &-10 {
      grid-template-columns: repeat(10, 1fr);
    }

    &-11 {
      grid-template-columns: repeat(11, 1fr);
    }

    &-12 {
      grid-template-columns: repeat(12, 1fr);
    }
  }
}


#heading.white-bg {
  background-color: #fff;
  padding: var(--padding-medium) var(--padding-medium) 0;
  margin: 0;
}

.accordion {
  cursor: pointer;


  .no-pointer-events {
    pointer-events: none;
  }

  .answer {
    transition: all 0.2s ease;
    visibility: hidden;
    transform: translateY(-10px);
    opacity: 0;
    height: 0;
  }

  .question {
    position: relative;
    display: flex;
  }

  .plus-icon {
    padding-right: 20px;
    height: 14px;
    margin-top: 10px;
    position: relative;
  }

  h2 .plus-icon {
    margin-top: 13px;
  }

  @media (min-width: $screen-small) {

    h2 .plus-icon {
      margin-top: 20px;
    }

    h3 .plus-icon {
      margin-top: 15px;
    }

    h4 .plus-icon {
      margin-top: 10px;
    }

    h5 .plus-icon {
      margin-top: 7px;
    }

    h6 .plus-icon, h7 .plus-icon {
      margin-top: 5px;
    }
  }


  .horizontal, .vertical {
    position: absolute;
    top: 3px;
    left: 0;
    transform: translate(0, -50%);
    background: black;

    @media (min-width: $screen-small) {
      top: 8px;
    }
  }

  .horizontal {
    height: 3px;
    width: 13px;
  }

  .vertical {
    transition: transform 0.2s ease, opacity 0.3s ease, margin 0.2s ease;
    height: 13px;
    width: 3px;
    left: 5px;
  }

  &.open {
    .answer {
      visibility: visible;
      transform: translateY(0);
      opacity: 1;
      height: 100%;
    }

    .question .vertical {
      transform: rotate(450deg);
      margin: -7px 0 0 0;
    }
  }
}

.how-to-section {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: var(--padding-small) 0;

  @media (min-width: $screen-medium) {
    flex-direction: row;
    gap: 20px;
    padding: var(--padding-small) 0 0;
  }

  img {
    padding: var(--padding-small) 0 0;
    @media (min-width: $screen-medium) {
      max-width: 50%;
      padding: 0;
    }
  }
}

.how-to-admin .how-to-section {
  > div, > form {
    width: calc(50% - 10px);
  }

  [data-editor-type='Inline'] {
    word-wrap: break-word;
  }
}

  .total-time {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

