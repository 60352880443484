﻿@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&family=Roboto:wght@400;500&display=swap');

html {
  line-height: 1.5;
}

body {
  color: var(--font-color);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5;
  font-display: swap;
  font-size: 16;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0 0 var(--padding-mini) 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Montserrat';
    font-weight: 600;
  }

  h1,
  .text-size-1 {
    @include fluid-size(600, 1200, 24, 48);
  }

  h2,
  .text-size-2 {
    @include fluid-size(600, 1200, 22, 38);
  }

  h3,
  .text-size-3 {
    @include fluid-size(600, 1200, 20, 30);
  }

  h4,
  .text-size-4 {
    @include fluid-size(600, 1200, 18, 24);
  }

  h5,
  .text-size-5 {
    @include fluid-size(600, 1200, 16, 20);
  }

  h6,
  .text-size-6 {
    @include fluid-size(600, 1200, 16, 18);
  }

  .text-size-7 {
    @include fluid-size(600, 1200, 10, 12);
  }

  .text-size-8 {
    @include fluid-size(600, 1200, 8, 10);
  }

  a {
    color: var(--font-color);

    &:visited,
    &:hover {
      color: var(--font-color);
      text-decoration: underline;
    }
  }

  .light {
    font-weight: 200;
  }

  .bold {
    font-weight: bold;
  }

  .italic {
    font-style: italic;
  }

  .upper {
    text-transform: uppercase;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .campaign-price {
    color: var(--color-red);
  }

  @media (min-width: $screen-medium) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0 0 var(--padding-xsmall) 0;
    }
  }
}
