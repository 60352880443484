﻿.basket-row {

  .icon.icon-trash {
    height: 20px;
    width: 20px;
  }

  .price {
    text-align: right;
  }

  .delete {
    text-align: right;
  }

  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-content: center;
  padding-bottom: var(--padding-small);
  margin-bottom: var(--padding-small);
  border-bottom: 1px solid var(--color-grey-lighter);

  img {
    grid-row: 1 / span 2;
  }

  .image {
    background-color: var(--color-grey-lighter)
  }

  .row-actions {
    display: flex;
    a {
      align-items: center;
      border: 2px solid #809fb8;
      border-radius: 50%;
      display: flex;
      height: 35px;
      justify-content: center;
      text-decoration: none;
      width: 35px;
    }

    input {
      height: 35px;
      margin: 0 5px;
      padding: 0;
      text-align: center;
      width: 35px;
      &[disabled] {
        color: var(--color-grey-dark);
      }
    }
  }
}



@media (min-width: $screen-small) {
  .basket-row {
    grid-template-columns: auto 1fr 1fr 100px;

    .name, .row-actions {
      grid-row: 1 / span 2;
    }

    .delete {
      grid-column: 4 / span 1;
      grid-row: 1;
    }

    .price {
      grid-column: 4 / span 1;
      grid-row: 2;
    }
  }
}