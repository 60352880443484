﻿@import "@components/filter.scss";
@import "@components/paging.scss";
@import "@elements/select.scss";

.main-search {
  position: relative;
  grid-area: search;
  width: 100%;
  margin: var(--main-search-margin);
  padding: 0 var(--padding-small);
  max-width: 800px;

  > .search-form {
    position: relative;

    > .input-group {
      box-shadow: var(--main-search-box-shadow);
      background-color: var(--main-search-background-color);
      border-radius: var(--main-search-border-radius);
      min-height: 50px;
      grid-template-areas: var(--main-search-grid-areas);
      grid-template-columns: var(--main-search-grid-columns);

      > .button {
        height: auto;
        background-color: transparent;
        grid-area: search-icon;
        padding: 0 var(--padding-small) 0 0;

        > i {
          margin-left: 0;
        }
      }

      > input[type='text'] {
        grid-area: input;
        background-color: var(--main-search-input-background-color);
        padding: 0 var(--padding-small);
        height: auto;
      }

      > a.search-clear {
        grid-area: clear-icon;
        height: 50px;
        display: none;
        justify-content: center;
        align-items: center;
        padding: 0 var(--padding-xsmall);

        > i.icon-close {
          height: 25px;
          width: 25px;
          opacity: 0.75;
          transition: opacity 0.2s;

          &:hover,
          &:active {
            opacity: 1;
          }
        }
      }
    }

    > .search-results {
      display: none;
      position: fixed;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      max-width: 1200px;
      background-color: var(--color-white);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      margin: 0;
      padding: 1rem;
      z-index: 110;
      overflow: auto;
      grid-template-areas:
        'header header' 'content-left content-left' 'content-right content-right';
      gap: var(--padding-small);
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto 1fr;

      @media (min-width: $screen-small) {
        width: 80%;
        grid-template-areas:
          'header header' 'content-left content-right';
      }

      > div {
        margin-bottom: var(--padding-xsmall);
        // &:last-child {
        //   margin-bottom: var(--padding-small);
        // }
      }

      > .search-header {
        grid-area: header;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .search-suggestion {
          > span {
            font-weight: 600;
          }
        }
      }

      .search-link {
        display: flex;
        justify-content: flex-center;
        align-items: center;
        width: 100%;
      }

      .category {
        text-transform: capitalize;
        margin-bottom: var(--padding-xsmall);
        font-weight: 600;
      }

      .content-right {
        grid-area: content-right;
        padding: 0;
        max-width: 400px;

        @media (min-width: $screen-small) {
          padding: 0 var(--padding-small);
        }
      }

      .brands-container {
        order: 1;
        margin-bottom: var(--padding-xsmall);
      }

      .pages-container {
        order: 2;
      }

      .pages-container,
      .brands-container {
        > a.search-link {
          margin-bottom: var(--padding-xsmall);
          padding-bottom: var(--padding-xsmall);
          border-bottom: solid 1px var(--color-grey-light-second);

          &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }

        img {
          border-radius: 6px;
        }
      }

      > .products-container {
        grid-area: content-left;

        > .product {
          grid-template-columns: 1fr auto;
          grid-gap: var(--gap-row-medium) var(--gap-column-small);
          position: relative;
          margin-bottom: var(--padding-xsmall);
          padding-bottom: var(--padding-xsmall);
          border-bottom: solid 1px var(--color-grey-light-second);

          &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
          }

          > .product-link {
            grid-template-columns: 50px auto;
            position: relative;

            .prices {
              .rec-price {
                text-decoration: line-through;
                margin-left: 10px;
              }
            }

            .price-container {
              display: flex;
              justify-content: space-between;
              align-items: center;

              > div {
                width: 50%;
                display: flex;
                align-items: center;

                @media (max-width: $screen-small) {
                  width: 100%;
                }
              }

              .product-search-label {
                flex-direction: column;
                align-items: flex-start;
              }


              .prices {
                text-align: right;
                justify-content: flex-end;

                @media (max-width: $screen-small) {
                  display: none;
                }
              }
            }


            .flag {
              height: auto;
              width: auto;
              font-size: 10px;
              border-radius: 6px;
              padding: 0.2rem 0.5rem;
              display: flex;
              justify-content: center;
              align-items: flex-start;
              max-width: 68px;
              max-height: 60px;
              overflow: hidden;

              @media (max-width: $screen-small) {
                margin: .2rem 0 1rem;
              }

              > span {
                height: auto;
                width: auto;
                display: inline-block;
                border-radius: 0;
                border: none;
              }
            }

            .flag.hidden {
              display: none;
            }
          }

          > .product-actions {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            justify-content: space-between;

            > .dropdown {
              height: 50px;
              width: calc(100% - 0.5rem);
              margin-right: 0.5rem;
              min-width: 180px;
              cursor: pointer;

              > .dd-menu {
                width: 100%;

                > .dd-item {
                  * {
                    pointer-events: none;
                  }
                }
              }

              > .dd-toggle {
                background-color: var(--color-grey-lighter);
                border-radius: var(--element-radius);
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0 0.5rem;
                height: 50px;
                position: relative;

                &::after {
                  content: '';
                  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' %3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'%3E%3C/path%3E%3C/svg%3E");
                  position: absolute;
                  top: 50%;
                  right: 20px;
                  transform: translateY(-50%) rotate(90deg);
                  width: 10px;
                  height: 10px;
                }
              }

              > .dd-menu {
                width: fit-content;
                border: none;
                box-shadow: none;
                position: relative;
                width: 100%;
                margin-top: 50px;

                .dd-item {
                  border-radius: 0;

                  &:not(:first-child) {
                    border-top: none;
                  }
                }
              }
            }

            > a.button {
              min-width: 100px;

              &[disabled='disabled'] {
                cursor: default;
                font-size: 14px;
              }
            }
          }
        }
      }

      > .search-product.outofstock {
        grid-area: content-left;

        label,
        span {
          color: var(--color-grey-medium);
        }

        img {
          filter: grayscale(100%);
        }
      }

      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}

@media (max-width: $screen-medium) {
  header {
    > .main-search {
      > .search-form {
        > .search-results {
          left: 0;
          width: 100%;
          transform: translateX(0);
          grid-template-areas:
            'header' 'content-left' 'content-right';
          grid-template-columns: 1fr;
          grid-template-rows: auto 1fr 1fr;

          .product-actions {
            a.button {
              height: 50px;
            }
          }
          .content-right {
            padding: 0;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-small) {
  header {
    .main-search {
      padding: 0;

      > .search-form {
        > .input-group {
          grid-template-columns: 1fr min-content min-content;

          > .button,
          > #search-input,
          > .search-clear {
            grid-row-start: 1;
          }

          > #search-input {
            grid-column-start: 1;
          }

          > .search-clear {
            grid-column-start: 2;
          }

          > .button {
            grid-column-start: 3;
            padding: 0 1rem;
          }
        }
        .search-results {
          .products-container {
            .product {
              grid-template-columns: auto;
              text-align: center;
              margin-bottom: var(--padding-small);
              grid-gap: 0;

              .product-link {
                grid-template-columns: auto 1fr;

                > div {
                  label {
                    text-align: left;
                  }
                }
              }

              img {
                margin: 0 auto;
                width: 100%;
              }

              .product-actions {
                grid-template-columns: 1fr auto;
                gap: 0 var(--padding-xsmall);

                .dropdown {
                  margin: 0;
                  min-width: auto;
                  height: 40px;
                  width: 100%;

                  > .dd-toggle {
                    height: 40px;
                    padding: 0 0.5rem;
                    font-size: 14px;
                    white-space: nowrap;
                  }
                }

                a.button {
                  min-width: 100px;
                  height: 40px;
                  text-align: center;
                  justify-content: center;

                  &.hide {
                    > span {
                      display: none;
                    }

                    > .icon {
                      margin-left: 0;
                    }
                  }
                }

                > .price-container {
                  label {
                    font-size: 20px;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
