/*TABLE*/
table {
  border-collapse: collapse;
  width: 100%;
  margin: var(--padding-xsmall) 0;

  thead {
    tr {
      border-bottom: 2px solid #cdcdcd;
      td {
        padding: var(--padding-mini) 0;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #d7d7d7;
      &:last-child{
        border-bottom: none;
      }
      td {
        &:first-child{ padding: var(--padding-xsmall) 0;}
        &:last-child{ padding: var(--padding-xsmall) 0; }
        padding: var(--padding-xsmall) var(--padding-mini);

        p, em, label, div{
          margin: 0;
        }
      }
    }
  }

  &.table-padding {
    tbody {
      tr {
        td {
          padding: var(--padding-xsmall) var(--gap-column-small);
        }
      }
    }
  }

  @media (min-width: $screen-medium) {
    &.table-padding {
      tbody {
        tr {
          td {
            padding: var(--padding-xsmall) var(--gap-column-medium);
          }
        }
      }
    }
  }
}