﻿.menu {
  background-color: #fff;
  height: 100%;
  overflow: scroll;
  position: fixed;
  top: 0;
  width: 100%;

  &.open {
    display: block !important;
  }

  .mobile-header {
    background-color: var(--color-grey-lighter);
    display: flex;
    justify-content: space-between;
    width: 100%;

    .site-logo {
      text-indent: -9999px;

      // .img-logo {
      //   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 92 18'%3E%3Cg fill='%23081B50' fill-rule='evenodd'%3E%3Cpath d='M90.5 1.4c-1-1-2.2-1.4-3.8-1.4H80v18h2V2h4.5c2 0 3.3 1.2 3.3 3.5 0 2.2-1.4 3.5-3.3 3.5h-2.3v1.9h2.5c1.6 0 2.8-.5 3.8-1.4 1-1 1.5-2.3 1.5-4 0-1.8-.5-3.1-1.5-4M70.5 1.4c-1-1-2.2-1.4-3.8-1.4H60v18h2V2h4.5c2 0 3.3 1.2 3.3 3.5 0 2.2-1.4 3.5-3.3 3.5h-2.3v1.9h2.5c1.6 0 2.8-.5 3.8-1.4 1-1 1.5-2.3 1.5-4 0-1.8-.5-3.1-1.5-4M51.4 0 45 10.1V18h2.2v-7.5L54 0zM45 6.1 40.7 0H38l5.7 8zM27.6 0 20 18h2.4l1.9-4.6h6l-.8-1.9h-4.4l3.4-8.1L34.6 18H37L29.4 0zM11 18h2V0h-2zM0 0v18h2.1V9.7H9v-2H2.1V0z'/%3E%3C/g%3E%3C/svg%3E");
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   background-size: contain;
      //   display: block;
      //   height: 70px;
      //   width: 120px;
      // }
    }

    .icon-container .icon.close-menu {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cg fill='none' fill-rule='nonzero' stroke='%23081B50' stroke-linecap='square' stroke-width='2'%3E%3Cpath d='m4.3 3.6 21.2 21.2M4.3 25.5 25.5 4.3'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  > .container {
    nav {
      &.flavour-menu {
        span {
          grid-column: 1 / span 2;
        }

        a {
          img {
            display: block;
          }
        }
      }

      &.menu-item {
        display: none;
      }

      &.customer-menu {
        a.icon-container {
          display: flex;
          padding: 5px 0;
        }
      }

      > .brands {
        max-height: none;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        > a {
          padding: var(--padding-mini) 0;
          font-size: 20px;
        }
      }
    }

    .locationSelector {
      > .ls-mobile-header {
        visibility: visible;
        display: block;
      }

      > .custom-select {
        border: none;

        > a {
          display: none;
        }

        > .custom-select-options {
          display: block !important;
          margin-top: 0;
          box-shadow: none;

          > a {
            @include fluid-size(600, 1200, 16, 16);
            height: 50px;
            padding: 0;

            &:hover {
              border: none;
              text-decoration: underline;
            }

            > i {
              background-size: 38px;
              height: 24px;
              width: 26px;
            }
          }
        }
      }
    }
  }

  @media (min-width: $screen-medium) {
    background: rgba(255, 255, 255, 0.98);
    height: auto;
    overflow: auto;
    padding: var(--padding-medium) 0;
    top: var(--header-height);

    > .container {
      grid-template-columns: 1fr 1fr auto;
      nav {
        &.menu-item {
          display: grid;
        }

        .locationSelector {
          > .ls-mobile-header {
            visibility: hidden;
            display: none;
          }
        }

        > .brands {
          max-height: 600px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          > a {
            padding: var(--padding-mini) var(--padding-xsmall) var(--padding-mini) 0;
            font-size: 18px;
          }
        }
      }
    }
  }
}
