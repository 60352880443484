/*BUTTONS*/
.button {
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: var(--element-radius);
  box-sizing: border-box;
  color: var(--font-color);
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  justify-content: space-between;
  text-align: left;
  text-decoration: none;
  vertical-align: middle;
  @include button-size(var(--padding-small), var(--element-height));
  @include fluid-size(600, 1200, 16, 16);
  position: relative;

  .icon {
    height: 22px;
    width: 22px;
  }

  &.button-primary {
    background-color: var(--button-primary-background-color);
    border-color: var(--button-primary-border-color);
    color: var(--button-primary-color);

    &:hover:not([disabled]) {
      background-color: var(--button-primary-background-color-hover);
      border-color: var(--button-primary-background-color-hover);
    }

    &:active:not([disabled]) {
      background-color: var(--button-primary-background-color-active);
      border-color: var(--button-primary-background-color-active);
    }
  }

  &.button-secondary {
    background-color: var(--button-secondary-background-color);
    border-color: var(--button-secondary-background-color);
    color: var(--button-secondary-color);

    &:hover:not([disabled]) {
      background-color: var(--button-secondary-background-color-hover);
      border-color: var(--button-secondary-background-color-hover);
    }

    &:active:not([disabled]) {
      background-color: var(--button-secondary-background-color-active);
      border-color: var(--button-secondary-color-active);
    }
  }

  &.button-grey {
    background-color: var(--color-grey-light);
    border-color: var(--color-grey-light);
    color: var(--font-color);

    &:hover:not([disabled]),
    &:active:not([disabled]) {
      background-color: var(--color-grey-medium);
      border-color: var(--color-grey-medium);
    }
  }

  &.button-red {
    background-color: var(--dark-b-lg-sec-20);
    border-color: var(--dark-b-lg-sec-20);
    color: white;
  }

  &.go-to-checkout {
    display: none;

    &.button {
      background-color: var(--gotocheckout-button-bg-color);
      color: var(--color-white);
      height: var(--button-min-height-mobile);
      justify-content: center;
      align-items: center;
      border-radius: 6px;
    }

    &.open {
      display: flex;
    }
  }

  &.button-banners, &.a.button-banners {
    background-color: var(--banner-button-bg-color);
    color: white;
    width: auto;
    display: inline-flex;
  }

  &.button-custom {
    background-color: transparent;
    border: 2px solid var(--color-green);
  }

  &.button-fullsize {
    justify-content: center;
    width: 100%;
  }

  &.load {
    &::before {
      content: '';
      position: absolute;
      right: 20px;
      border: 3px solid transparent;
      border-top: 3px solid var(--color-white);
      border-radius: 50%;
      width: 22px;
      height: 22px;
      vertical-align: -50%;
      animation: spin 1s linear infinite;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &[disabled='disabled'] {
    background-color: var(--button-disabled-background-color);
    border-color: var(--button-disabled-border-color);
    color: var(--button-disabled-color);
    box-shadow: none;
  }

  &.m-t-5 {
    margin-top: 5px;
  }
}

.icon {
  &.load {
    background-image: none !important;
    &::before {
      content: '';
      position: absolute;
      left: 20px;
      border: 3px solid transparent;
      border-top: 3px solid #fff;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      vertical-align: -50%;
      animation: spin 1s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
