aside.aside-menu {
  width: auto;
  grid-area: var(--nav-area);
  padding: 0 0 var(--padding-small);
  transition: all 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 9000;
  background-color: rgba(0, 0, 0, 0.5);

  &.open {
    display: block;
    > .nav-menu-content {
      transform: translate(0%, 0);
      opacity: 1;
    }
  }

  > a.aside-menu-toggle {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    justify-content: flex-start;
    align-items: center;
    padding: var(--padding-small);
    background-color: var(--color-grey-menutoggle);

    > i {
      padding-right: calc(var(--padding-xsmall) + var(--padding-small));
      background-position: right;
    }
  }

  > .nav-menu-content {
    width: 80%;
    background-color: var(--main-nav-background-color);
    z-index: 9000;
    height: 100vh;
    overflow: scroll;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    transform: translate(150%, 0);
    opacity: 0;
    transition: all 0.5s ease;
    transition-delay: 0.5s;
    height: 100vh;
    padding: var(--padding-small);

    > .nav-close-location {
      grid-template-columns: 1fr auto;
      margin-bottom: var(--padding-small);

      .custom-select-options {
        position: absolute;
        margin-top: 1px;
        z-index: 100;
      }

      > .aside-menu-close {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        > i {
          height: 30px;
          width: 30px;
          background-size: 30px 30px;
        }
      }
    }

    > nav {
      width: auto;
      display: flex;
      flex-direction: var(--main-nav-direction);
      padding-bottom: var(--padding-small);

      div.menu-item {
        position: relative;
        cursor: pointer;
        height: 50px;
        flex-direction: column;

        > a {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > i {
            transform-origin: center;
            width: 50px;
            height: 50px;
            background-size: 16px 16px;
          }
        }
      }

      div.active {
        height: auto;

        > ul {
          display: block;
        }

        > a {
          > i {
            transform: rotate(90deg);
          }
        }
      }

      > a,
      > div,
      > div a {
        color: var(--header-nav-anchor);
        // padding: var(--padding-xsmall) 0;
        // margin-bottom: var(--padding-xsmall);
        border-bottom: solid 1px var(--color-grey-light-second);
        font-size: 18px;
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;

        &:last-child {
          border: none;
        }

        &:hover {
          color: var(--header-nav-anchor-hover);
        }

        ul {
          display: none;
          padding: 0;
          margin: 0;
          list-style: none;
          width: 100%;

          > li {
            padding: 0 0 0 var(--padding-xsmall);
            margin: 0;
            display: flex;
            align-items: center;

            &:last-child {
              > a {
                border: none;
                margin-bottom: 0;
              }
            }

            > a {
              display: flex;
              align-items: center;
              height: auto;
              border-bottom: solid 1px var(--color-grey-light-second);
              padding: 0.5rem 0;
              line-height: 100%;

              &::before {
                content: '- ';
                padding-right: 0.5rem;
                font-weight: 600;
              }
            }

            > div.menu-item {
              display: flex;
              width: 100%;

              > a {
                justify-content: flex-start;

                &::before {
                  content: '- ';
                  padding-right: 0.5rem;
                  font-weight: 600;
                }

                > i {
                  position: absolute;
                  right: 0;
                }
              }

              &.active:last-child {
                  border-bottom: solid 1px var(--color-grey-light-second);
              }
              
            }
          }
        }
      }

      .menu-item {
        justify-content: space-between;
      }

      &.nav-main-brands {
        > a:last-child {
          font-size: 16px;
          text-decoration: underline;
        }
      }
    }

    > .product-bestsellers {
      padding-bottom: var(--padding-small);

      > a {
        display: grid;
        grid-gap: var(--gap-column-mini) var(--gap-column-xsmall);
        grid-template-areas:
          'pimg pbrand' 'pimg ptitle';
        grid-template-columns: 80px auto;
        grid-template-rows: repeat(2, auto);
        border-bottom: solid 1px var(--color-grey-lighter);
        padding-bottom: var(--padding-xsmall);
        margin-bottom: var(--padding-xsmall);

        > div.product-image {
          grid-area: pimg;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--color-grey-lighter);
          padding: var(--padding-xsmall);

          > img {
            width: 50px;
            height: 50px;
            overflow: hidden;
          }
        }

        > .product-brand {
          grid-area: pbrand;
          color: var(--color-grey-medium);
          text-transform: uppercase;
          font-size: 12px;
        }

        > .product-title {
          grid-area: ptitle;
          font-size: 14px;
        }
      }
    }

    > .news-lastest {
      padding-bottom: var(--padding-small);

      > a {
        display: grid;
        grid-template-areas: 'newsimg newstitle';
        grid-template-columns: auto auto;
        grid-template-rows: auto;
        grid-gap: var(--gap-column-mini) var(--gap-column-xsmall);
        justify-content: flex-start;
        align-items: center;
        border-bottom: solid 1px var(--color-grey-lighter);
        padding-bottom: var(--padding-xsmall);
        margin-bottom: var(--padding-xsmall);

        > .news-image {
          grid-area: newsimg;
          > img {
            width: 50px;
            height: 50px;
            overflow: hidden;
          }
        }

        > .news-title {
          grid-area: newstitle;
          font-size: 14px;
        }
      }
    }
  }

  label {
    display: block;
    font-weight: 400;
    color: var(--color-grey-medium);
    font-size: 1.2rem;
    padding-bottom: var(--padding-xsmall);
    text-transform: uppercase;
  }

  @media (min-width: $screen-medium) {
    background-color: var(--main-nav-background-color);
    width: var(--main-nav-width);
    max-width: var(--main-nav-width);
    display: block;
    height: 100%;
    z-index: unset;
    overflow: hidden;
    position: relative;
    top: unset;
    left: unset;

    &::before {
      content: unset;
    }

    > .nav-menu-content {
      width: 260px;
      z-index: unset;
      height: 100%;
      overflow: hidden;
      position: relative;
      top: unset;
      left: unset;
      z-index: unset;
      transform: unset;
      opacity: 1;
    }

    &.shrink {
      width: 70px;
      > .nav-menu-content {
        display: none;
      }

      > a.aside-menu-toggle {
        padding: var(--padding-small) var(--padding-xsmall);

        > i {
          background-position: center;
          @include svg-transform($clr-black, #{$icon-menu});
          background-size: 25px 25px;
          height: 50px;
          width: 50px;
        }

        span {
          display: none;
        }
      }
    }
  }
}
