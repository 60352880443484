.scroll-button {
  background-color: var(--scroll-button-background);
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: var(--element-radius);
  transition: background 0.2s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  display: none;

  &.scroll-mobile {
    bottom: 70px;
  }

  &:hover,
  &:active {
    background: var(--scroll-button-background-hover);
    i {
      color: var(--scroll-button-icon-hover);
    }
  }

  i {
    font-size: 20px;
    color: var(--scroll-button-icon);
  }
}

.admin-mode {
  .scroll-button {
    bottom: 60px;
  }
}

body[data-basket='1'] {
  .scroll-button {
    bottom: 60px;
  }
}
