﻿footer {
  background-color: var(--footer-bg-color);

  @media (max-width:641px) {
    footer .footer-top {
      background-color: var(--dark-c-bg);
    }
  }

  .footer-top {
    display: grid;
    place-items: center center;
    background: var(--footer-top-bg);
    background-image: var(--footer-top-bg-images);
    background-color: var(--footer-top-bg-color);
    background-repeat: no-repeat;
    background-size: cover;
    color: var(--footer-top-text);
    position: relative;
    text-align: center;
    margin-top: 0;

    .footer-puff-image {
      background-image: var(--logo-alt);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      margin: auto;
      height: var(--logo-alt-height);
      width: var(--logo-alt-width);
      margin-bottom: var(--padding-small);
    }

    > .container-small {
      padding-top: var(--padding-large);
      padding-bottom: var(--padding-large);
      width: 80vw;
    }
  }

  .footer-usp-block {
    background-image: var(--footer-usp-bg-image);
    background-color: var(--footer-usp-bg-color);

    > div.container {
      text-align: center;
      padding: var(--footer-usp-pad);
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    .footer-usp-customerServiceImg,
    .footer-usp-shippingImg,
    .footer-usp-discountsImg,
    .footer-usp-freshProductsImg {
      width: var(--footer-usp-icon-size);
      height: var(--footer-usp-icon-size);
      background-size: var(--footer-usp-icon-size) var(--footer-usp-icon-size);
      background-repeat: no-repeat;
      margin: 0 auto;
    }

    .footer-usp-customerServiceImg {
      @include svg-transform('', #{$icon-usp-customerservice});
    }

    .footer-usp-shippingImg {
      @include svg-transform('', #{$icon-usp-shipping});
    }

    .footer-usp-discountsImg {
      @include svg-transform('', #{$icon-usp-discounts});
    }

    .footer-usp-freshProductsImg {
      @include svg-transform('', #{$icon-usp-freshproducts});
    }
  }

  .footer-bottom {
    background-color: var(--footer-bottom-background);
    color: var(--footer-bottom-text-color);
    padding: 60px 0;
    margin-bottom: 0;

    .label-h4 {
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 24px;
      margin-bottom: var(--padding-xsmall);
      color: var(--footer-bottom-header-color);
    }

    a {
      color: var(--footer-bottom-text-color);

      &.footer-icon:hover {
        text-decoration: none;
      }
    }

    .footer-container {
      // grid-template-columns: auto;
      // grid-template-rows: repeat(3, auto);
      // place-content: center;
      > .footer-item {
        h4,
        p,
        div {
          text-align: center;
        }
      }

      .footer-links-right {
        nav {
          grid-gap: var(--padding-xsmall);
        }
      }
    }

    .footer-item {
      .inp-newsletter {
        height: auto;
      }
    }
  }

  .footer-logos {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    @media (min-width: $screen-small) {
      align-self: center;
      grid-column-start: 2;
      grid-column-end: 3;
    }

    .logo {
      justify-self: center;
      width: 150px;
      margin-top: 20px;

      .cls-1 {
        fill: #fcd600;
      }

      .cls-2 {
        fill: #b20029;
      }
    }

    .teh-certificate {
      background-image: url('~@assets/images/TEH-logo.png');
      background-size: 150px;
      height: 150px;
    }

    .verify-18-uk {
      background-image: url('~@assets/images/verify-18-uk.svg');
      background-size: contain;
      height: 150px;
      width: 135px;
    }
  }


  @media (min-width: $screen-medium) {
    .footer-top {
      margin: 0 inherit var(--padding-large) inherit;

      > .container-small {
        width: 50vw;
      }
    }

    .footer-usp-block {
      > div.container {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;

        > div {
          overflow: hidden;
        }
      }
    }

    .footer-bottom {
      .footer-container {
        grid-template-columns: repeat(3, minmax(100px, 45ch));
        grid-auto-rows: auto;
        place-content: center;

        > .footer-item {
          h4,
          p,
          div {
            text-align: left;
          }
        }
      }
    }
  }
}
