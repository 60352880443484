.breadcrumbs-container {
  padding: var(--padding-small);
  margin: 0;

  @media (min-width: $screen-medium) {
    padding: var(--padding-medium);
  }

  .breadcrumbs {
    background-color: unset;
    display: inline-block;
    @include fluid-size(600, strip-units($screen-medium), 12, 12);
    font-family: "Montserrat";
    color: #000;
    font-weight: 600;

    a {
      color: #000;
      padding: 0.5rem 0;

      &.active {
        font-weight: 500;
      }


      &:hover {
        text-decoration: none;
      }
    }

    .separator {
        font-weight: 500;
      @include fluid-prop-size(padding, 600px, $screen-medium, 0px, $padding-xsmall);
    }
  }
}

main {
  .breadcrumbs-container {
    padding: var(--padding-xsmall);
    margin: 0;

    @media (min-width: $screen-medium) {
      padding: var(--padding-small) var(--padding-medium);
    }
  }
}
