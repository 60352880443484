﻿.product-card-container {
  border-radius: var(--product-card-radius);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-white);
  height: 100%;
  position: relative;

  .product-card {
    background-color: var(--product-card-background);
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    margin: var(--product-card-margin);
    padding: var(--product-card-padding);
    padding-bottom: 0px;
    grid-gap: var(--product-card-grid-gap);
    background-color: var(--portrait-card-background);
    min-width: 160px;
    max-width: 400px;
    overflow: hidden;
    display: grid;

    @media (max-width: $screen-small) {
      grid-gap: var(--product-card-grid-gap-mobile);
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    .product-image {
      height: calc(140px + var(--padding-xsmall));
      padding: var(--padding-xsmall);
      margin-bottom: 10px;

      img {
        width: 140px;
        height: 120px;
      }

      .flag {
        position: absolute;
        display: flex;
        bottom: -15px;
        left: 0;
        height: 30px;
        width: auto;
        border-radius: 14px;

        > span {
          height: auto;
          width: auto;
          padding: 0.2rem 0.5rem;
          font-size: 14px;
          border: none;
        }
      }
    }

    .product-info {
      display: grid;
      grid-gap: var(--padding-xsmall);
      grid-column: 2 / span 1;
      grid-row: 1;
      position: relative;
      grid-column: 1;
      grid-row: 2;
      display: grid;
      grid-gap: var(--padding-xsmall);
      grid-row: auto;
      padding: 0;

      @media (max-width: $screen-small) {
        grid-gap: var(--padding-mini);
      }

      .prices {
        color: var(--color-grey-mid);
        @include fluid-size(600, 1200, 16, 18);

        > span {
          display: block;
        }

        .sales-price {
          color: var(--color-red);
          @include fluid-size(600, 1200, 16, 20);
        }

        .unit-price,
        .unitprice {
          text-decoration: line-through;
          @include fluid-size(600, 1200, 14, 16);
        }

        .rec-price {
          text-decoration: line-through;
          @include fluid-size(600, 1200, 16, 16);
        }

        [data-container='unitpriceouter'] {
          @include fluid-size(600, 1200, 12, 14);
        }
      }

      .brand {
        color: var(--color-grey-mid);
        // font-family: 'Montserrat';
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding-top: var(--padding-xsmall);
        @include fluid-size(600, 1200, 14, 16);
      }
    }
    // ##########################################
    // ## portrait-card mobile ##
    // ##########################################
    // ##########################################
    // ## vertical-card mobile ##
    // ##########################################
    &.vertical-card {
      grid-template-columns: 90px auto;
      grid-template-rows: auto;
      background: unset;
      margin-bottom: 0;
      padding: var(--padding-xsmall) var(--padding-small);

      &.even {
        background-color: var(--color-white);
      }

      .product-image {
        grid-column: 1;
        grid-row: 1;
        padding: 0;

        img {
          margin-top: 0;
        }
      }

      .product-info {
        grid-column: 2;
        justify-content: flex-start;
        padding: 0;

        .name {
          font-size: 14px;
          text-align: left;
        }

        .prices {
          text-align: left;
          display: block;

          .sales-price {
            @include fluid-size(600, 1200, 16, 16);
            font-weight: bold;
          }
        }
      }

      .product-actions,
      .product-info .attribute-bars {
        display: none;
      }
    }

    @media (min-width: $screen-xsmall) {
      .product-image {
        img {
          width: 140px;
          height: 140px;
        }
      }
    }

    @media (min-width: $screen-medium) {
      .product-actions {
        .dropdown {
          border-radius: var(--buysection-dropdown-radius);
        }

        > a.button {
          border-radius: var(--buysection-button-radius);
        }
      }
      // ##########################################
      // ## portrait-card desktop/tablet ##
      // ##########################################
      &.portrait-card {
        .product-image {
          padding: var(--padding-small);
          height: calc(240px + var(--padding-small));
          min-height: calc(140px + var(--padding-small));

          img {
            width: auto;
            height: 240px;
            max-height: 240px;
            min-width: 140px;
            min-height: 140px;
          }

          .flag {
            > span {
              padding: 0.3rem 1rem;
            }
          }
        }

        .product-actions {
          > .dropdown {
            align-self: flex-end;
          }

          > .button {
            align-self: flex-end;
          }
        }
      }
    }
  }

  .product-actions {
    display: grid;
    justify-self: center;
    align-items: flex-end;
    grid-column: 1;
    grid-row: 3;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    height: auto;
    max-width: 100%;
    width: 100%;
    margin-bottom: var(--product-actions-m-bottom);

    .price-info {
      text-align: center;
      color: #41695b;
      font-size: 14px;
      font-weight: 500;
    }

    .dropdown {
      .price-info {
        > p, div {
          margin-bottom: 0px;
        }

        .per-stuck {
          font-size: 12px;
          font-weight: 500;
          color: #989796;
        }

        &.out-of-stock {
          color: #656565;
        }
      }

      > .dd-selected {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > .dd-toggle {
        min-width: initial;
        background-color: var(--product-card-dropdown-background-color);
        padding: 0 0 0 var(--padding-xsmall);

        > div {
          margin-right: 0px;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: calc(100% - 28px);
          pointer-events: none;
        }
      }

      > .dd-menu {
        padding: 10px;


        .dd-item {
          padding: 7px 10px;
          margin-bottom: 5px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          @media (max-width: $screen-medium) {
            padding: 7px 7px;

            .v-per-stuck:not(.v-pack-price) {
              display: none;
            }
          }

          .price {
            width: 85%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            text-align: left;
            margin-bottom: 0px;

            .pack {
              width: 70%;
            }
          }

          .quantity-radio {
            display: flex;
            width: 25px;
            height: 25px;

            i {
              height: 12px;
              width: 12px;
            }
          }
        }

        .dd-item:last-child {
          margin-bottom: 0px;
        }

        .dd-item:not(.out-of-stock):has(span.best-can-price-flag) {
          padding-top: 15px;
        }
      }
    }

    > a.button {
      height: var(--button-min-height-mobile);
      border-radius: var(--buysection-button-radius_mobile);
      width: 100%;
    }

    a.button {
      justify-content: center;
      align-items: center;
      padding: 0 var(--padding-xsmall);
      color: white;

      &.button-red {
        background-color: var(--dark-b-lg-sec-20);
        border-color: var(--dark-b-lg-sec-20);
      }

      &.disabled {
        background-color: #989796;
        pointer-events: none;
      }

      > * {
        pointer-events: none;
      }

      > i {
        background-size: 18px 18px;
        margin: 0 var(--padding-mini) 2px 0;
      }

      > label {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &[disabled='disabled'] {
        justify-content: space-between;
      }
    }

    .single-price-info {
      text-align: center;
      /*color: var(--color-green-dark);
      @include fluid-size(600, 1200, 12, 14);
      font-size: 12px;
      font-weight: 600;*/
      background-color: var(--product-card-dropdown-background-color);
      padding: 0 var(--padding-xsmall);
      min-height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > p, div {
        margin-bottom: 0px;
      }

      &.out-of-stock {
        color: #656565;
      }
    }



    .dropdown,
    .single-price {
      height: 50px;
    }
  }
}



  .slick-slider {
    .product-card {
      .product-info {
        margin: var(--padding-small) 0;
      }
    }
  }

  .productCard-fly {
    position: absolute;
    display: block;
    z-index: 1000;
  }

  .productlist-horizontal {
    > .grid {
      grid-template-columns: repeat(auto-fill, minmax(min(160px, 100%), 1fr));
      min-width: 160px;
      grid-gap: var(--padding-small) var(--padding-xsmall);
    }

    @media (min-width: $screen-xsmall) {
      > .grid {
        grid-template-columns: repeat(auto-fill, minmax(min(180px, 100%), 1fr));
        min-width: 180px;
      }
    }

    @media (min-width: $screen-small) {
    }

    @media (min-width: $screen-medium) {
      > .grid {
        grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
        min-width: 300px;
      }
    }

    @media (min-width: $screen-large) {
      max-width: 1700px;
    }
  }

  .productlist-horizontal,
  .productlist-horizontalscroll {
    > .productlist-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--padding-xsmall) 0;

      &.productlist-head-center {
        justify-content: center;
        flex-direction: column;
      }

      > h3 {
        padding: 0;
        margin: 0;
      }

      > .link {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  .productlist-horizontalscroll {
      .product-card-container {
          margin: 0 3px;
      }
  }

  .glide {
    .glide__slide {
      .product-card {
        .product-image {
          img {
            width: auto;
            height: 120px;
          }
        }
      }
    }
  }

  .keen-slider__slide {
    .product-card {
      height: 100%;
    }
  }

  .dd-item {
    text-align: left;
    text-decoration: none;
    padding: var(--padding-xsmall) 17px;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;
    width: auto;
    background-color: #FAFAFA;
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    position: relative;

    &.out-of-stock {
      color: #c2c2c2;
      pointer-events: none;

      .best-can-price-flag {
        display: none;
      }
    }

    .v-quantity {
      @media (max-width: $screen-xsmall) {
        width: 40%;
      }
    }

    &:hover {
      background-color: #eee;
      color: #000;
    }

    
    .v-quantity, .price {
       pointer-events: none;
    }

    &.dd-selected {
      .quantity-radio {
        background-color: var(--dark-b-lg-sec-20);
        border: none;
        color: var(--color-white);

        i {
          display: inline-block;
          height: 18px;
          width: 18px;
          margin: auto;
        }
      }
    }

    .best-can-price-flag {
      position: absolute;
      left: 0px;
      top: 0px;
      background: #7BA0C4;
      color: white;
      border-radius: 9px 0px 19px;
      padding: 1px 9px 1px 5px;
      font-family: Roboto;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
    }

    .quantity {
      width: 40%;
      font-size: 18px;
    }

    .price {
      font-size: 16px;
      text-align: center;
    }

    .v-per-stuck:not(.v-pack-price) {
      font-size: smaller;
      font-weight: 500;
      color: var(--color-grey-mid);
    }

    .quantity-radio {
      width: 42px;
      height: 42px;
      background: #F3F3F3;
      border: 2px solid #E7E7E7;
      border-radius: 21px;

      i {
        display: none;
      }
    }
  }

  .dd-item:not(.out-of-stock):has(span.best-can-price-flag) {
    div {
      font-weight: bold;

      > p.v-per-stuck {
        font-weight: bold;
      }
    }
  }

.dd-quantity-menu {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  max-height: 70vh;
  z-index: 100;
  background-color: var(--color-white);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px -10px 10px 2px rgba(0, 0, 0, 0.25);

  .dd-quantity-menu-items {
    display: flex;
    flex-direction: column;
    gap: var(--padding-mini);
    padding: 0px 25px 10px 25px;
    overflow: scroll;

    .dd-item {
      > div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      > p {
        margin-bottom: 0px;
      }
    }
    }

    > div:last-child {
      margin-bottom: 100px;
    }
  }
}

  .close-btn {
    margin: -45px 10px 10px 0px;
    background: #F3F3F3;
    border: 1px solid #E7E7E7;
    box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 36px;
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    flex-shrink: 0;

    i {
      width: 15px;
      height: 15px;
    }
  }

  .dd-product-info {
    display: flex;
    flex-direction: row;
    padding: 25px;
    gap: var(--padding-mini);


    .dd-product {
      padding: var(--padding-xsmall);
      text-decoration: none;

      span {
        display: block;
      }

      .brand {
        font-weight: 700;
        font-size: 20px;
        line-height: 16px;
        color: #989796;
        padding-bottom: 15px;
      }

      .name {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-decoration-line: underline;
      }
    }
  }

  .basket-btn {
    background-color: var(--color-white);
    box-shadow: 0px -5px 16px rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    min-height: 98px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--padding-xsmall);
    padding: var(--padding-small);

    > a.button {
      width: 80%;
      border-radius: 6px;
    }

    
  }

  .contents-container {
    display: contents;

    > div {
      visibility: hidden;
    }

    .dd-quantity-menu {
      visibility: visible;
    }
  }


  .product-card, .dd-quantity-menu {
    .product-image {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: var(--product-image-radius);
      background-color: var(--color-grey-lighter);
      padding: 8px 10px 3px 8px;
      grid-row: 1;
    }
  }

.dd-product-info {
  display: grid;
  grid-template-columns: 100px auto;
}

.frozen {
  touch-action: none;
  pointer-events: none;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  .dd-quantity-menu {
    pointer-events: auto;
  }
}

