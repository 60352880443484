.dropdown {
  height: var(--element-height-medium);
  position: relative;
  width: 100%;

  > .dd-toggle {
    align-items: center;
    display: inline-flex;
    background-color: var(--color-white);
    height: var(--element-height-medium);
    padding: 0 calc(var(--padding-xsmall) + 16px) 0 var(--padding-xsmall);
    width: 100%;
    transition: transform 0.2s ease-in-out;
    text-align: left;


    &.in-stock {
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 16px;
        top: calc(50% + -8px);
        right: 1rem;
        transform: rotate(90deg);
        transition: transform 0.2s ease-in-out;
        @extend .icon, .icon-arrow-right;
      }
    }
  }

  > .dd-menu {
    display: none;
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    z-index: 100;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
    background-color: var(--color-white);

    .dd-item:not(.qty-selector) {
      background-color: #fff;
      text-align: left;
      text-decoration: none;
      display: block;
      padding: var(--padding-xsmall) 16px var(--padding-xsmall) var(--padding-xsmall);
      text-align: left;
      cursor: pointer;
      white-space: nowrap;
      width: auto;

      &:hover {
        background-color: #eee;
        color: #000;
      }

      &.dd-selected {
        @extend .icon-check;
        background-repeat: no-repeat;
        background-position: right var(--padding-xsmall) center;
        background-size: 16px;
        padding: var(--padding-xsmall) calc(26px + var(--padding-xsmall)) var(--padding-xsmall) var(--padding-xsmall);
      }
    }
  }

  &.dd-down {
    > .dd-menu {
      bottom: auto;
      top: 100%;
    }
  }

  &.dd-center {
    > .dd-menu {
      bottom: auto;
      top: 100%;
      transform: translateY(-50%);
    }
  }

  &.open {
    > .dd-menu {
      display: block;
    }

    > .dd-quantity-menu {
      display: flex;
      flex-direction: column;
      z-index: 6300;
    }

    > .dd-toggle {
      &:before {
        transform: rotate(-90deg);
      }
    }
  }

  &[disabled='disabled'] {
    > .dd-toggle {
      color: var(--color-grey-light);

      &:before {
        @extend .icon-arrow-right, .grey;
      }
    }
  }

  @media (min-width: $screen-small) {
    > .dd-toggle {
      padding: 0 calc(var(--padding-small) + 16px) 0 var(--padding-small);
    }
  }

  @media (min-width: $screen-medium) {
    height: var(--element-height-medium);

    > .dd-toggle {
      height: var(--element-height-medium);
    }
  }
}
