/*IMAGE*/

img {
  max-width: 100%;
}

figure {
  margin: 0;
  padding: 0;
}

hr {
  border-top: 1px solid var(--color-grey-light);
}

.img-circle {
    border-radius: 50%;
}