﻿body {
  background-color: var(--layout-background);
  display: grid;
  grid-template-areas: var(--layout-areas);
  grid-template-columns: var(--layout-template-columns);
  grid-template-rows: var(--layout-template-row);
  margin: 0;
  padding: 0;
}

header {
  grid-area: header;
}

main {
  grid-area: main;
  overflow-x: initial;
  max-width: 100vw;

  @media (min-width: $screen-small) {
  overflow-x: hidden;
  }

  .container,
  .container-small {
    max-width: var(--view-max);
  }
}

footer {
  grid-area: footer;
}

.container-small {
  @include container(var(--padding-xsmall));
}

.container {
  @include container(var(--padding-small));

  @media (max-width: $screen-small) {
    @include container(var(--padding-xsmall))
  }
}

.container-large {
  @include container(0);
}

.container-small,
.container,
.container-large,
.container-full {
  &.container-space-bottom-none {
    margin-bottom: 0;
  }

  &.container-space-bottom-mini {
    margin-bottom: var(--padding-mini);
  }

  &.container-space-bottom-xsmall {
    margin-bottom: var(--padding-xsmall);
  }

  &.container-space-bottom-small {
    margin-bottom: var(--padding-small);
  }

  &.container-space-bottom-large {
    margin-bottom: var(--padding-large);
  }

  &.container-space-top-none {
    margin-top: 0;
  }

  &.container-space-top-mini {
    margin-top: var(--padding-mini);
  }

  &.container-space-top-xsmall {
    margin-top: var(--padding-xsmall);
  }

  &.container-space-top-small {
    margin-top: var(--padding-small);
  }

  &.container-space-top-large {
    margin-top: var(--padding-large);
  }
}

.grid {
  display: grid;
  grid-gap: var(--gap-row-small) var(--gap-column-small);

  &.grid-auto-height {
    grid-auto-rows: minmax(min-content, max-content);
  }

  &.grid-xsmall {
    grid-gap: var(--padding-mini);
  }

  &.grid-small {
    grid-gap: var(--padding-xsmall);
  }

  &.columns-xsmall-1 {
    grid-template-columns: 1fr;
  }

  &.columns-xsmall-2 {
    grid-template-columns: repeat(2, 1fr);

    .stretch {
      grid-column: 1 / span 2;
    }
  }
}

.flex {
  align-items: center;
  display: flex;

  &.flex-space-between {
    justify-content: space-between;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.flex-start {
    justify-content: flex-start;
  }
}

.block {
    display: block; 
}

.m-b-small {
  margin-bottom: var(--padding-xsmall);
}

.m-t-small {
  margin-top: var(--padding-xsmall);
}

.m-l-small {
  margin-left: var(--padding-xsmall);
}

.m-r-small {
  margin-right: var(--padding-xsmall);
}

.m-b-medium {
  margin-bottom: var(--padding-small);
}

.m-t-medium {
  margin-top: var(--padding-small);
}

.m-l-medium {
  margin-left: var(--padding-small);
}

.m-r-medium {
  margin-right: var(--padding-small);
}

@media (min-width: $screen-small) {
  .grid {
    &.columns-small-1 {
      grid-template-columns: 1fr;
    }

    &.columns-small-2 {
      grid-template-columns: repeat(2, 1fr);

      .stretch {
        grid-column: 1 / span 2;
      }
    }

    &.columns-small-3 {
      grid-template-columns: repeat(3, 1fr);

      .stretch {
        grid-column: 1 / span 3;
      }
    }

    &.columns-small-4 {
      grid-template-columns: repeat(4, 1fr);

      .stretch {
        grid-column: 1 / span 4;
      }
    }

    &.columns-desktop3-mobile2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (min-width: $screen-medium) {
  .grid {
    grid-gap: var(--gap-row-medium) var(--gap-column-medium);

    &.grid-xsmall {
      grid-gap: var(--padding-xsmall);
    }

    &.grid-small {
      grid-gap: var(--gap-row-small) var(--gap-column-small);
    }

    &.columns-medium-2 {
      grid-template-columns: repeat(2, 1fr);

      .stretch {
        grid-column: 1 / span 2;
      }
    }

    &.columns-medium-3 {
      grid-template-columns: repeat(3, 1fr);

      .stretch {
        grid-column: 1 / span 3;
      }
    }

    &.columns-medium-4 {
      grid-template-columns: repeat(4, 1fr);

      .stretch {
        grid-column: 1 / span 4;
      }
    }

    &.columns-medium-5 {
      grid-template-columns: repeat(5, 1fr);

      .stretch {
        grid-column: 1 / span 5;
      }
    }

    &.columns-medium-6 {
      grid-template-columns: repeat(6, 1fr);

      .stretch {
        grid-column: 1 / span 6;
      }
    }
  }

  .m-r-mini {
    margin-right: var(--padding-mini);
  }

  .m-b-small {
    margin-bottom: var(--padding-small);
  }

  .m-t-small {
    margin-top: var(--padding-small);
  }

  .m-l-small {
    margin-left: var(--padding-small);
  }

  .m-r-small {
    margin-right: var(--padding-small);
  }

  .m-b-medium {
    margin-bottom: var(--padding-medium);
  }

  .m-t-medium {
    margin-top: var(--padding-medium);
  }

  .m-l-medium {
    margin-left: var(--padding-medium);
  }

  .m-r-medium {
    margin-right: var(--padding-medium);
  }
  .hidden-large {
    display: none !important;
  }
}

@media (min-width: $screen-large) {
  .grid {
    &.columns-large-4 {
      grid-template-columns: repeat(4, 1fr);

      .stretch {
        grid-column: 1 / span 4;
      }
    }
  }

  // .main {
  //   .container,
  //   .container-small {
  //     max-width: var(--view-max);
  //   }
  // }
}

@media (max-width: $screen-xsmall) {
  .hidden-xsmall {
    display: none !important;
  }
}

@media (max-width: $screen-small) {
  .hidden-small {
    display: none !important;
  }
}

@media (max-width: $screen-medium) {
  .hidden-medium {
    display: none !important;
  }
}
