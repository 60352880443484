/*DROPDOWNS*/
.select {
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Lager_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 6.1 5.6' style='enable-background:new 0 0 6.1 5.6;' xml:space='preserve'%3E%3Cpolygon points='3.4,0 2.6,0.7 4.4,2.4 0,2.4 0,3.2 4.4,3.2 2.6,5 3.4,5.6 6.1,2.8 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right var(--padding-xsmall) center;
  background-size: 16px;
  border: none;
  cursor: pointer;
  height: var(--element-height-small);
  outline: 0;
  padding: 0 calc(var(--padding-xsmall) * 2) 0 var(--padding-xsmall);
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;

  &.select-border {
    border: 1px solid var(--color-grey-light);
  }

  &[disabled] {
    color: var(--color-grey-medium);
    cursor: not-allowed;
  }

  &.error {
    border-bottom: solid 2px var(--color-red);
  }
}

.custom-select {
  background-color: #fff;
  border: 1px solid var(--color-grey-light);
  cursor: pointer;
  position: relative;
  z-index: 100;
  padding: 0;

  a {
    align-items: center;
    display: flex;
    height: var(--element-height-small);
    justify-content: flex-start;
    text-decoration: none;
    padding: 0 var(--padding-xsmall);

    &:hover {
      text-decoration: none;
      //border-bottom: solid 2px #eee;
      background-color: #eee;
    }

    i {
      margin-right: var(--padding-mini);
    }
  }

  a.active {
    &:hover {
      text-decoration: none;
      border-bottom: none;
      background-color: transparent;
    }

    &::after {
      content: '';
      position: absolute;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6.1 5.6'%3E%3Cdefs/%3E%3Cg fill='%23000000'%3E%3Cpath d='M3.4 0l-.8.7 1.8 1.7H0v.8h4.4L2.6 5l.8.6 2.7-2.8z'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px;
      width: 26px;
      height: 14px;
      right: 20px;
      transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
  }

  &.open {
    a.active::after {
      transform: rotate(90deg);
    }
  }

  .custom-select-options {
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0 1px 3px 0 var(--color-grey-light), 0 1px 2px 0 var(--color-grey-light);
    margin-top: var(--padding-mini);
    padding: 0;
    width: 100%;

    &.open {
      display: block;
    }
  }
}

@media (min-width: $screen-small) {
  .select {
    background-position: right var(--padding-small) center;
    padding: 0 calc(var(--padding-small) * 3) 0 var(--padding-small);
  }

  .custom-select {
    a.active {
      background-position: right var(--padding-small) center;
      padding: 0 calc(var(--padding-small) * 3) 0 var(--padding-small);
    }
  }
}

@media (min-width: $screen-medium) {
  .select {
    height: var(--element-height-medium);
  }

  .custom-select {
    border: none;

    a {
      height: var(--element-height-medium);
    }

    .custom-select-options {
      position: absolute;
    }
  }
}
