﻿.banner {
  .banner-header {
    a {
      float: right;
    }
  }
  /*GRIDTYPES*/
  .banner-j {
    grid-template-rows: auto 200px;
  }
  /*MEDIA*/
  .banner-media {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    position: relative;

    a {
      display: block;
      text-decoration: none;
    }

    video {
      max-width: 100%;
    }

    button,
    span {
      left: var(--padding-small);
      right: var(--padding-small);
      position: absolute;
      width: calc(100% - (var(--padding-small) * 2));
      z-index: 1;
    }

    button {
      bottom: var(--padding-small);
    }

    span {
      top: var(--padding-small);
    }
    /*BANNERTYPES*/
    &.banner-background {
      background-position: center right;
      background-size: auto 80%;
    }

    &.banner-instagram-big {
      grid-column: span 2;
      grid-row: span 2;
    }

    &.banner-secondary-type {
      button {
        width: auto;
      }
    }

    &.banner-logo {
      background-size: auto;
      height: 150px !important;
    }
  }
  /*ARTICLE-BANNER*/
  .banner-article {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content min-content;

    .media {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 300px;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: var(--padding-medium);
    }

    &.banner-s {
      grid-template-columns: 50% 50%;

      @media (min-width: $screen-small) {
        grid-template-columns: 40% 60%;
      }

      .text {
        padding: 0;
        justify-content: normal;

        .subtitle,
        .button {
          display: none;
        }
      }

      .media {
        min-height: 120px;
        grid-column: 2;
        grid-row: 1;

        @media (min-width: $screen-small) {
          grid-column: 2;
          grid-row: 1;
          min-height: 120px;
        }

        @media (min-width: $screen-medium) {
          grid-column: 2;
          grid-row: 1;
          min-height: 190px;
        }

        @media (min-width: $screen-large) {
          grid-column: 2;
          grid-row: 1;
          min-height: 230px;
        }
      }
    }

    &.banner-o {
      grid-template-columns: 100%;

      .media {
        grid-column: 1;
        grid-row: 2;
        min-height: auto;

        &.has-content {
          grid-column: 1;
          grid-row: 2;
          min-height: 100%;
        }
      }

      .text {
        padding: 0;
        text-align: center;

        .subtitle,
        .button {
          display: none;
        }
      }
    }

    &.banner-m {
      grid-template-columns: 100%;

      .media {
        grid-column: 1;
        grid-row: 2;
        min-height: auto;

        &.has-content {
          grid-column: 1;
          grid-row: 2;
          padding-top: 30%;
          min-height: 100%;
        }
      }

      .text {
        display: none;
      }
    }
  }
  /*BANNER-STEPS*/
  .banner-step {
    align-items: center;

    img {
      justify-self: end;
    }

    .text {
      display: flex;
      flex-direction: column;

      &.vertical-align-middle {
        align-self: center;
      }

      &.vertical-align-top {
        align-self: flex-start;
      }

      &.vertical-align-bottom {
        align-self: flex-end;
      }
    }

    &:nth-child(even) {
      img {
        grid-column: 2;
        grid-row: 1;
        justify-self: start;
      }

      .text {
        grid-column: 1;
        grid-row: 1;
        justify-self: end;
        text-align: right;
      }
    }

    a {
        text-decoration: underline
    }
  }

  .banner-panorama {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;


    .banner-link {
      grid-column: 1/4;
      grid-row: 1/3;
      display: grid;
    }

    img {
      grid-column: 1 / 4;
      grid-row: 1 / 3;
    }

    .text {
      grid-column: 1 / 4;
      grid-row: 1 / 3;
      align-self: start;
      margin: 10px;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (min-width: $screen-small) {
        margin: 30px;
      }

      .title, .subtitle {
        display: block;
        max-width: 50%;
        color: inherit;

        @media (min-width: $screen-small) {
          max-width: 45%;
        }
      }

      .title {
        font-family: "Montserrat";
        text-transform: uppercase;
        font-size: 20px;
        line-height: 22px;
        font-weight: var(--banner-title-font-weight);

        @media (min-width: $screen-small) {
          font-size: 55px;
          line-height: 58px;
        }
      }

      .subtitle {
        font-size: 14px;
        line-height: 16px;
        font-weight: var(--banner-subtitle-font-weight);
        font-style: var(--banner-subtitle-font-style);

        @media (min-width: $screen-small) {
          font-size: 32px;
          line-height: 35px;
        }
      }
    }

    .button-container {
      grid-column: 1 / 4;
      grid-row: 2 / 3;
      align-self: end;
      margin: 10px;
      display: flex;
      justify-content: flex-start;

      @media (min-width: $screen-small) {
        margin: 30px;
      }

      .button-banners {
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        font-weight: var(--banner-button-font-weight);
        font-size: 18px;
        line-height: 18px;
        height: auto;
        padding: 8px;

        @media (min-width: $screen-small) {
          font-size: 24px;
          line-height: 26px;
          padding: 10px 20px;
        }
      }

      .icon {
        margin-left: 10px;
      }

      .icon-double-arrow {
        height: 12px;
        width: 12px;

        @media (min-width: $screen-small) {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  @media (min-width: $screen-medium) {
    .banner-j {
      grid-template-columns: 5fr 2fr;
      grid-template-rows: auto;
    }

    .banner-k {
      grid-template-columns: 2fr 2fr 3fr;
    }

    .banner-media {
      button,
      span {
        left: var(--padding-medium);
        right: var(--padding-medium);
        width: calc(100% - (var(--padding-medium) * 2));
      }

      button {
        bottom: var(--padding-medium);
      }

      span {
        top: var(--padding-medium);

        &.vertical-align-bottom {
          top: unset;
          bottom: 100px;
        }

        &.vertical-align-middle {
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &.banner-background {
        background-position: center right;
        background-size: 100% auto;
      }
    }

    .banner-article {
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: min-content;

      .media {
        grid-column: 2;
        grid-row: 1;
        min-height: 550px;
      }

      .text {
        grid-column: 1;
        grid-row: 1;
        padding: var(--padding-large);

        &.vertical-align-middle {
          justify-content: center;
        }

        &.vertical-align-top {
          justify-content: flex-start;
        }

        &.vertical-align-bottom {
          justify-content: flex-end;
        }
      }
    }
  }
}

.flexible-columns-3, .flexible-columns-2 {
  .banner-panorama {
    .text {
      margin: 10px 10px 0;

      .title, .subtitle {
        max-width: 60%;
      }


      .title {
        font-size: 20px;
        line-height: 22px;
      }

      .subtitle {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .button-container {
      margin: 0 10px 10px;

      .button {
        font-size: 12px;
        padding: 3px 10px;

        .icon-double-arrow {
          height: 12px;
          width: 12px;
        }

        @media (max-width: $screen-xsmall) {
          font-size: 18px;
          padding: 8px;
        }
      }
    }
  }
}

.flexible-columns-2 {
  @media (min-width: $screen-medium) {
    .banner-panorama {

      .text {
        margin: 10px;

        .title {
          font-size: 32px;
          line-height: 35px;
        }

        .subtitle {
          font-size: 24px;
          line-height: 26px;
        }
      }

      .button-container {
        .button-banners {
          font-size: 19px;
          line-height: 22px;
          padding: 10px 20px;
        }
      }
    }
  }
}

.banners-slider {

  .navigation-wrapper {
    position: relative;

    .dots {
      display: flex;
      padding: 10px 0;
      justify-content: center;
    }

    .dot {
      border: none;
      width: 10px;
      height: 10px;
      background: #c5c5c5;
      border-radius: 50%;
      margin: 0 5px;
      padding: 5px;
      cursor: pointer;
    }

    .dot:focus {
      outline: none;
    }

    .dot--active {
      background: #000;
    }

    .arrow {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      fill: #fff;
      cursor: pointer;
      padding: 0px;

      @media (max-width: $screen-xsmall) {
        width: 30px;
        height: 30px;
        background-size: 40%;
      }
    }

    .arrow--left {
      left: 5px;
      fill: "#fff";
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' %3E%3Cpath d='M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z' %3E%3C/path%3E%3C/svg%3E");
    }

    .arrow--right {
      left: auto;
      right: 5px;
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 24 24' %3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'%3E%3C/path%3E%3C/svg%3E");
    }
  }
}